import React from "react";
import HomePageLayout from "../layout/HomePageLayout";

const Home = () => {
    return (
        <HomePageLayout title="Shop Produce Online">
            <div style={{ padding: '20px', textAlign: 'left', fontSize: '2.2em', lineHeight: '1.4', marginTop: '20px' }}>
                <p>Welcome to Tom Produce - Your Premier Wholesaler for Fresh Produce in Los Angeles</p>
                <p>At Tom Produce, we specialize in providing the highest quality fresh produce to restaurants, grocery stores, and food service businesses across Los Angeles. With a commitment to excellence, we source our fruits and vegetables from trusted farms and suppliers, ensuring that every product meets our rigorous standards for freshness and flavor.</p>
                <p>Our extensive inventory includes seasonal produce, organic options, and specialty items, all delivered with exceptional service and reliability. Whether you're looking for everyday staples or unique ingredients to elevate your menu, we have you covered.</p>
                <p>We pride ourselves on building strong partnerships with our clients, understanding their unique needs, and providing tailored solutions to help their businesses thrive. Our team of dedicated professionals is here to support you with prompt deliveries, competitive pricing, and expert guidance.</p>
                <p>Discover the difference of working with a wholesaler that truly cares about your success. Explore our product offerings and join our growing family of satisfied clients today!</p>
            </div>
        </HomePageLayout>
    );
};

export default Home;