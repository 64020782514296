/* eslint-disable jsx-a11y/anchor-is-valid */
import React,  { useEffect, useState, useRef } from "react";
//import { toast } from "react-hot-toast";
import { toast } from 'react-toastify';
import { Link, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import MetaData from "../../layout/MetaData";
import { MDBDataTable } from "mdbreact";
import { useNavigate } from "react-router-dom";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import _  from 'lodash';

import AdminLayout from "../../layout/AdminLayout";
import HeaderAdmin from "../../layout/HeaderAdmin";
import Loader from "../../layout/Loader";
import { EditableNumberCell, ButtonCell, RightAlignedCell, CenterAlignedCell } from "../../layout/CustomMDBDataTableCell";
import { ORDER_STATUS, ADMIN_MENU_ITEMS, ORDER_USER_RIGHTS, ORDER_STATUS_ICON, DELIVERY_TYPE, QUICKBOOKS_STATUS, USER_ROLES, PRICE_THRESHOLD_STATUS } from "../../../constants/constants.js";
import { 
  useAdminOrderDetailsQuery, 
  useAdminUpdateOrderMutation, 
  useAdminShipOrderMutation,
  useAdminPickupOrderMutation, 
  useAdminDeliverOrderMutation, 
  useAdminFinishPickedUpOrderMutation,
  useAdminTransferToQuickbooksMutation, 
  useAdminDeleteOrderMutation, 
  useAdminMarkOrderAsViewedMutation,
  useAdminUploadOrderDocumentImagesMutation,
  useAdminDeleteOrderDocumentImageMutation,
  // Old orders
  useAdminOldOrderDetailsQuery, 
  useAdminUpdateOldOrderMutation, 
  useAdminDeleteOldOrderMutation,
 } from "../../../redux/api/orderAdminApi";
import { useAdminGetInventoryQuery } from "../../../redux/api/inventoriesAdminApi";
import { useAdminGetAllDriversQuery } from "../../../redux/api/adminApi";
import { valueIsANonNegativeNumber, valueIsANumber } from "../../../utils/validators";
import { CalculateOrderSummary, calculateSellPriceThresholds, getSellPrice, SellPriceDoubleCheck } from "../../../utils/utilities";
import { ConfirmDialog, ConfirmDialogShippingOrder } from "../ConfirmDialog";
import { ProductSelection } from "../../product/ProductSelection";
import { DetermineUserOrderRights, DetermineUserOldOrderRights } from "../AdminActionEligibilities";
import { useLazyAdminLogoutQuery /*, useLazyAdminQuickbooksAuthorizeQuery*/ } from "../../../redux/api/adminAuthApi";
import { useAdminGetAllUsersQuery } from "../../../redux/api/adminApi";
import { set } from "lodash";

const ProcessOrder = () => {
  const isOldOrder = window.location.href.includes('/admin/oldorders/');

  const params = useParams();
  const orderId = params?.id;
  const navigate = useNavigate();

  // Redux
  const { isLoading: loadingOrderDetails, data: orderDetails, error: loadingOrderDetailError } = useAdminOrderDetailsQuery({ id: orderId, isOldOrder }, { refetchOnMountOrArgChange: true });
  const [adminUpdateOrder, { data: updatedOrderData, isLoading: isUpdatingOrder, error: orderUpdateError, isSuccess: orderUpdateSuccess }] = useAdminUpdateOrderMutation();
  const [adminShipOrder, { data: shipOrderData, isLoading: isShippingOrder, error: orderShippingError, isSuccess: orderShippingSuccess }] = useAdminShipOrderMutation();
  const [adminPickupOrder, { data: pickUpOrderData, isLoading: isPickingUpOrder, error: orderPickingUpError, isSuccess: orderPickingUpSuccess }] = useAdminPickupOrderMutation();
  const [adminDeliverOrder, { data: deliverOrderData, isLoading: isDeliveringOrder, error: orderDeliverError, isSuccess: orderDeliverSuccess }] = useAdminDeliverOrderMutation();
  const [adminFinishPickeDupOrder, { data: finishPickedUpOrderData, isLoading: isFinishingPickedUpOrder, error: finishPickedUpOrderError, isSuccess: finishPickedUpOrderSuccess }] = useAdminFinishPickedUpOrderMutation();
  const [adminDeleteOrder, { isLoading: isDeletingOrder, error: orderDeleteError, isSuccess: orderDeleteSuccess }] = useAdminDeleteOrderMutation(isOldOrder);
  //const [adminTransferToQuickbooks, { data: quickbookData, isLoading: isTransferingToQuickbooks, error: quickbooksTransferError, isSuccess: quickbooksTransferSuccess }] = useAdminTransferToQuickbooksMutation();
  const [adminMarkOrderAsViewed] = useAdminMarkOrderAsViewedMutation();

  // state
  const [order, setOrder] = useState(null); 
  const [itemIndexToRemove, showRemoveItemConfirmDialog] = useState(-1);
  const [showingUpdateShippedOrderConfirm, showUpdateShippedOrderConfirmDialog] = useState(false);
  const [showingUpdateDeliveredOrderConfirm, showUpdateDeliveredOrderConfirmDialog] = useState(false);
  const [showingDeleteOrderConfirm, showDeleteOrderConfirmDialog] = useState(false);
  const [showingShippingConfirm, showShippingConfirmDialog] = useState(false);
  const [showingPickingUpConfirm, showPickingUpConfirmDialog] = useState(false);
  const [showingDeliveryConfirm, showDeliveryConfirmDialog] = useState(false);
  const [status, setStatus] = useState("");
  const [extraName, setExtraName] = useState();
  const [orderItems, setOrderItems] = useState([{}]);
  const [thereAreValueChanges, setThereAreValueChanges] = useState(false);
  const [formUpdated, setFormUpdated] = useState(false);
  const [refreshKey, setRefreshKey] = useState(0); 
  const [adminLogout] = useLazyAdminLogoutQuery();
  //const [adminAuthorizeQuickbooks, { data: quickbooksAuthorizationData, isLoading: isAuthorizingQuickbooks, error: quickbooksAuthError }] = useLazyAdminQuickbooksAuthorizeQuery();
  const [notes, setNotes] = useState();
  const [history, setHistory] = useState("");
  const [tempUpdatedInventory, setTempUpdatedInventory] = useState({});
  const [selectedDriverId, setSelectedDriverId] = useState();
  const [deliverType, setDeliverType] = useState();
  const [pallet, setPallet] = useState();
  const [shippingFee, setShippingFee] = useState();
  const [originalShippingFee, setOriginalShippingFee] = useState();
  const [terms, setTerms] = useState();
  const [PONumber, setPONumber] = useState();
  const [salesRep, setSalesRep] = useState();
  const [orderDate, setOrderDate] = useState();
  const [orderNoQB, setOrderNoQB] = useState();

  //const [orderUpdatedTime, setOrderUpdatedTime] = useState();
  let [clonedOrderItems, setclonedOrderItems] = useState([]);
  const [totalValues, setTotalValues] = useState({});

  // Slice data
  const { loadingAdmin, user } = useSelector((state) => state.adminAuth);
  const { inventory } = useSelector((state) => state.inventory);
  const { inventoryDataOrdersByProduct } = useSelector((state) => state.inProgressOrders);
  const { inventoryDataPurchasesByProduct } = useSelector((state) => state.inProgressPurchases);

     // Query for data
  const {isLoading: isLoadingInventory } = useAdminGetInventoryQuery();
  const {data: allDriverData } = useAdminGetAllDriversQuery();
 
  // Update image
  const fileInputRef = useRef(null);
  const [selectedFile, setSelectedFile] = useState();
  //const [newDocumentName, setNewDocumentName] = useState('Delivery Slip');
  const [imagesPreview, setImagesPreview] = useState();
  const [uploadedImages, setUploadedImages] = useState([]);
  const [imageIdToDelete, setImageIdToDelete] = useState(null);
  const [verified, setVerified] = useState(false);

  const [uploadProductImages, { data: uploadImageData, isLoading: isUploadingImages, error: errorUploadingImages, isSuccess: uploadImagesSuccess }] = useAdminUploadOrderDocumentImagesMutation();
  const [deleteProductImage, { data: deleteImageData, isLoading: isDeletingImage, error: deleteError },] = useAdminDeleteOrderDocumentImageMutation();

  // Clean up the temp inventory
  useEffect(() => {
    setTempUpdatedInventory({});
  }, [inventory]);

  useEffect(() => {
    if (loadingOrderDetailError) {
      toast.error(loadingOrderDetailError?.data?.message);
      if (loadingOrderDetailError.status === 401) {
        adminLogout();
      } else if (loadingOrderDetailError.status === 404) {
        navigate(isOldOrder ? "/admin/history" : "/admin/orders");
      }
    } 
  }, [loadingOrderDetailError]);

  useEffect(() => {
    if (user && orderDetails?.order) {
      const order = orderDetails.order;
      setOrder(order);

      setVerified(order.verified);

      // Mark the order as viewed if not viewed
      const timestamp = new Date(order.updatedAt).getTime();
      if (!user.viewOrderHistory || !user.viewOrderHistory[order._id] || user.viewOrderHistory[order._id].timestamp < timestamp) {
        adminMarkOrderAsViewed({ orderId: order._id, body: {timestamp, status: order.status}} );
      }
    }
  }, [user, orderDetails]);
  
  useEffect(() => {
    considerEnableUpdateButton();
  }, [notes, extraName, orderDate, orderNoQB, salesRep, shippingFee, pallet, deliverType, selectedDriverId, terms, PONumber, verified]);

  useEffect(() => {
    if (order) {
      setUploadedImages(order.documents);

      if (order.status) {
        setStatus(order.status);
      }
      setNotes(order.notes);
      if (order.history) {
        let historyString = "";
        order.history.forEach((item, index) => {
          historyString += `${index + 1}. ${item} \n`;
        });
        setHistory(historyString);
      }

      if (order.orderItems) {
        setOrderItems(JSON.parse(JSON.stringify(order.orderItems)));    
        const clonedItems = JSON.parse(JSON.stringify(order.orderItems));
        clonedItems.push({});
        setclonedOrderItems(JSON.parse(JSON.stringify(clonedItems)));
      }

      if (order.deliverType) {
        setDeliverType(order.deliverType);
      }

      if (order.driver) {
        setSelectedDriverId(order.driver._id);
      }

      setTerms(order.terms);
      setPONumber(order.PONumber);
      if (order.salesRep) {
        setSalesRep(order.salesRep);
      }
      if (order.shippingFee !== undefined) {
        setShippingFee(order.shippingFee.toFixed(2));
        setOriginalShippingFee(order.shippingFee.toFixed(2));
      }

      setPallet(order.pallet);
      setOrderDate(order.orderDate);
      setOrderNoQB(order.orderNoQB);

      if (order.extraName) {
        setExtraName(order.extraName);
      }
    }
  }, [order]);

  useEffect(() => {
    if (clonedOrderItems && inventory) {
      setTotals();
    }
  }, [inventory, clonedOrderItems]);

  // Update (process) order callback
  useEffect(() => {
    if (orderUpdateError) {
      toast.error(orderUpdateError?.data?.message);
    }
    if (orderUpdateSuccess) {
      //toast.success(updatedOrderData?.message);
      const updatedOrder = updatedOrderData?.order;
      if (updatedOrder) {
        setStatus(updatedOrder.status);
        setOrderItems(updatedOrder.orderItems);
        setclonedOrderItems(JSON.parse(JSON.stringify(updatedOrder.orderItems)));
        considerEnableUpdateButton();
      }

      if (updatedOrderData?.updatedInventory) {
        setTempUpdatedInventory(updatedOrderData.updatedInventory);
      }
    }
  }, [orderUpdateError, orderUpdateSuccess]);

  // Ship order callback
  useEffect(() => {
    if (orderPickingUpError) {
      toast.error(orderPickingUpError?.data?.message);
    } if (orderShippingError) {
      toast.error(orderShippingError?.data?.message);
    } else if (orderShippingSuccess) {
      const shippedOrder = shipOrderData?.order;
      if (shippedOrder) {
        setStatus(shippedOrder.status);
        setOrderItems(shippedOrder.orderItems);
        setclonedOrderItems(JSON.parse(JSON.stringify(shippedOrder.orderItems)));
        considerEnableUpdateButton();
      }

      if (shipOrderData?.updatedInventory) {
        setTempUpdatedInventory(shipOrderData.updatedInventory);
      }
    } else if (orderPickingUpSuccess) {
      const shippedOrder = pickUpOrderData?.order;
      if (shippedOrder) {
        setStatus(shippedOrder.status);
        setOrderItems(shippedOrder.orderItems);
        setclonedOrderItems(JSON.parse(JSON.stringify(shippedOrder.orderItems)));
        considerEnableUpdateButton();
      }

      if (pickUpOrderData?.updatedInventory) {
        setTempUpdatedInventory(pickUpOrderData.updatedInventory);
      }
    }
  }, [orderShippingError, orderShippingSuccess, orderPickingUpSuccess]);

  // Deliver order callback
  useEffect(() => {
    if (finishPickedUpOrderError) {
      toast.error(finishPickedUpOrderError?.data?.message);
    } else if (orderDeliverError) {
      toast.error(orderDeliverError?.data?.message);
    } else if (orderDeliverSuccess && deliverOrderData) {
      //toast.success(deliverOrderData?.message);
      const deliveredOrder = deliverOrderData?.order;
      if (deliveredOrder) {
        setStatus(deliveredOrder.status);
        setOrderItems(deliveredOrder.orderItems);
        setclonedOrderItems(JSON.parse(JSON.stringify(deliveredOrder.orderItems)));
        considerEnableUpdateButton();
      }

      if (deliverOrderData?.updatedInventory) {
        setTempUpdatedInventory(deliverOrderData.updatedInventory);
      }
    } else if (finishPickedUpOrderSuccess && finishPickedUpOrderData) {
      //toast.success(deliverOrderData?.message);
      const deliveredOrder = finishPickedUpOrderData?.order;
      if (deliveredOrder) {
        setStatus(deliveredOrder.status);
        setOrderItems(deliveredOrder.orderItems);
        setclonedOrderItems(JSON.parse(JSON.stringify(deliveredOrder.orderItems)));
        considerEnableUpdateButton();
      }

      if (finishPickedUpOrderData?.updatedInventory) {
        setTempUpdatedInventory(finishPickedUpOrderData.updatedInventory);
      }
    }
  }, [orderDeliverError, orderDeliverSuccess, finishPickedUpOrderSuccess, finishPickedUpOrderError]);

  useEffect(() => {
    considerEnableUpdateButton();
    // If last row is not empty, add a new row
    if (clonedOrderItems && clonedOrderItems.length > 0 && clonedOrderItems[clonedOrderItems.length - 1].name) {
      addItemRowHandler();
    }
  }, [clonedOrderItems, formUpdated]);

  /*
  useEffect(() => {
    if (quickbooksTransferError) {
      toast.error(quickbooksTransferError?.data?.message);
    } else if (quickbooksTransferSuccess) {
      if (quickbookData) {
        setOrder(quickbookData.order);
        if (quickbookData?.message) {
          toast.success(quickbookData?.message);
        }
      }
    }
  }, [quickbooksTransferError, quickbooksTransferSuccess]);
  */
  /*
  useEffect(() => {
    if (quickbooksAuthorizationData) {
      if (quickbooksAuthorizationData.authorized === true) {
        performActionAfterQuickbooksAuth();
      } else {
        const authUrl = quickbooksAuthorizationData.authUrl;
        if (authUrl) {
          const newWindow = window.open(authUrl, '', 'width=600,height=400');
          if (!newWindow || newWindow.closed || typeof newWindow.closed === 'undefined') {
            // Popup was blocked
            alert('Popup was blocked. Please disable popup blocker!');
          }
        } else {
          toast.error("Authorization failed. Please contact administrator");
        }
      }
    }
  }, [quickbooksAuthorizationData]);

  useEffect(() => {
    if (quickbooksAuthError) {
      toast.error(quickbooksAuthError?.data?.message);
    }
  }, [quickbooksAuthError]);
*/

  useEffect(() => {
    if (orderDeleteError) {
      toast.error(orderDeleteError?.data?.message);
    } else if (orderDeleteSuccess) {
      //toast.success("Order Removed");
      if (!isLoadingInventory) {
        navigate(isOldOrder ? "/admin/history" : "/admin/");
      }
    }
  }, [orderDeleteError, orderDeleteSuccess, isLoadingInventory]);

  const isLoading = loadingOrderDetails || loadingAdmin || isUpdatingOrder || isShippingOrder || isPickingUpOrder || isDeliveringOrder || isFinishingPickedUpOrder || isPickingUpOrder || isDeletingOrder || /*isTransferingToQuickbooks ||*/ isUploadingImages || isDeletingImage/* || isAuthorizingQuickbooks*/;

  // Update
  const updateOrderHandler = () => {
    const incompletItem = clonedOrderItems.find(item => item.name && (item.finalQuantity === undefined || item.finalPrice === undefined));
    if (incompletItem) {
      toast.error(`Please enter price/quantity for ${incompletItem.name}!`);
      return;
    }

    // Clean up the cloned order items
    const validRows = clonedOrderItems.filter(item => item.name && item.finalQuantity !== undefined && item.finalPrice !== undefined);
    if (isOrderUpdated()) {
      //console.log("Updating order ...", clonedOrderItems);
      let thereIsError = false;
      validRows.forEach((item, index) => {
        if (item.finalPrice === undefined || item.finalPrice <= 0) {
          toast.error(`${item.name}'s price is not entered.`);
          thereIsError = true;
        }
      });

      if (!thereIsError) {
        for (let index = 0; index < clonedOrderItems.length; index++) {
          const item = clonedOrderItems[index];
          if (item.product) {
            const product = inventory[item.product]?.product;

            const { ok, message } = SellPriceDoubleCheck(user, product, item.finalPrice);
            if (!ok) {
              toast.error(message);
              return; 
            }
          }
        }
        

        let orderData = appendShippingData({ itemList: validRows, notes});
        if (terms && terms !== order.terms) {
          orderData.terms = terms;
        }
        if (PONumber && PONumber !== order.PONumber) {
          orderData.PONumber = PONumber;
        }
        if (salesRep && salesRep !== order.salesRep) {
          orderData.salesRep = salesRep;
        }
        if (orderDate && orderDate !== order.orderDate) {
          orderData.orderDate = orderDate;
        }
        if (orderNoQB && orderNoQB !== order.orderNoQB) {
          orderData.orderNoQB = orderNoQB;
        }

        if (extraName && extraName !== order.extraName) {
          orderData.extraName = extraName;
        }

        if (verified !== order.verified) {
          orderData.verified = verified;
        }

        adminUpdateOrder({ id: orderId, isOldOrder, body: orderData });
      }
    } 
  };

  const displayQuickbooksStatus = () => {
    const messae = quickbooksTransferred === QUICKBOOKS_STATUS.TRANSFERRED ? "Invoice has been generate on Quickbooks" : 
    (quickbooksTransferred === QUICKBOOKS_STATUS.ERROR 
      ? "There was error generatin invoice on Quickbooks" 
      : "Invoice was not generated on Quickbooks");
      alert(messae);
  };

  /*
  // Check if the quickbooks auth is successful or already authorized
  const performActionAfterQuickbooksAuth = () => {
    // Clear the notification
    const quickbooksAction = localStorage.getItem('quickbooksAction');
    console.log("Performing action after quickbooks auth ...", quickbooksAction);

    // Transfer to quickbooks
    if (quickbooksAction === 'transferToQuickbooks') {
      if (!isTransferingToQuickbooks) {
         adminTransferToQuickbooks({ id: orderId });
      }
    }
    localStorage.removeItem('quickbooksAction');
  };

  const handleStorageEvent = (event) => {
    console.log("Handling storage event ...", event.key);
    if (event.key === 'quickbooksAuthSuccess') {
      // If the quickbooks auth is successful, proceed transfering to quickbooks
      if (event.newValue === 'true') {
        performActionAfterQuickbooksAuth();
        //toast.success("Quickbooks authorization successful! Please proceed with the action!");
      }
      localStorage.removeItem('quickbooksAuthSuccess');
    }
  };

  // Listen for the notification from the new tab
  useEffect(() => {
    window.removeEventListener('storage', handleStorageEvent);
    console.log("Adding event listener for storage event ...");
    window.addEventListener('storage', handleStorageEvent);

    // Cleanup event listener on component unmount
    return () => {
      console.log("Removing event listener for storage event ...");
      window.removeEventListener('storage', handleStorageEvent);
      localStorage.removeItem('quickbooksAction');
      localStorage.removeItem('quickbooksAuthSuccess');
    };
  }, []);
  */

  // ----------------- Upload document image --------------------------------
  useEffect(() => {
    if (errorUploadingImages) {
      toast.error(errorUploadingImages?.data?.message);
    }
    if (deleteError) {
      toast.error(deleteError?.data?.message);
    }
  }, [errorUploadingImages, deleteError]);

  useEffect(() => {
    if (uploadImageData) {
      setOrder(uploadImageData.order);
      setImagesPreview();
      //setNewDocumentName();
      toast.success("Document(s) Uploaded");
    }
  }, [uploadImageData]);

  useEffect(() => {
    if (deleteImageData) {
      setOrder(deleteImageData.order);
      if (deleteImageData.order.documents.length === 0) {
        setVerified(false);
        setFormUpdated(!formUpdated);
      }
      toast.success("Document Removed");
    }
  }, [deleteImageData]);

  const onChangeImageFiles = (e) => {
    const file = e.target.files[0];
    // Append new files to the existing state
    setSelectedFile(file);
        
    const reader = new FileReader();
    reader.onload = () => {
      if (reader.readyState === 2) {
        setImagesPreview(reader.result);
      }
    };

    reader.readAsDataURL(file);
  };

  const handleResetFileInput = () => {
    if (fileInputRef.current) {
      fileInputRef.current.value = "";
    }
  };

  const handleImagePreviewDelete = () => {
    setImagesPreview();
    //setNewDocumentName();
  };

  const submitUploadImageHandler = (e) => {
    e.preventDefault();

    /*
    // Check if document name is empty
    if (!newDocumentName || newDocumentName.trim() === "") {
      toast.error("Please enter document name!");
      return;
    }
    */

    const formData = new FormData();
    formData.append('files', selectedFile);
    //formData.append('documentName', newDocumentName);
    
    uploadProductImages({ id: params?.id, formData });
  };

  const setTotals = (overwritePallet = true) => {
    if (clonedOrderItems && inventory) {
      const totals = CalculateOrderSummary(clonedOrderItems, inventory);
      setTotalValues(totals);
      if (overwritePallet) {
        setPallet(totals.estPallet);
      }
    }
  }

  const deleteImage = (imgId) => {
    deleteProductImage({ id: params?.id, body: { imgId } });
  };

  const confirmDeletingDocument = () => {
    if (imageIdToDelete) {
      deleteImage(imageIdToDelete);
      setImageIdToDelete(null);
    }
  }

  /*
  const handleDocumentNameChange = (e) => {
    setNewDocumentName(e.target.value);
  };
  */

  // --------------------------------- Render ----------------------------------
  // If the order, inventory, user are not loaded yet, show the loader
  if (isLoadingInventory || loadingOrderDetails || loadingAdmin || user === null || order === null || inventory === null) {
    return <Loader />;
  }
  //--------------------------------- Functions ---------------------------------
  function isOrderUpdated() {
    const validRows = clonedOrderItems.filter(item => item.name);
    return order && (!_.isEqual(validRows, orderItems) || (notes ?? "") !== (order.notes ?? "") || order.deliverType !== deliverType || (order.deliverType === DELIVERY_TYPE.DELIVER && ((order.driver && order.driver._id !== selectedDriverId) || (!order.driver && selectedDriverId !== null && selectedDriverId !== undefined))) || originalShippingFee !== shippingFee || order.terms !== terms || order.PONumber !== PONumber || order.salesRep !== salesRep || orderDate !== order.orderDate || (orderNoQB ? true : false) !== (order.orderNoQB ? true : false) || extraName !== order.extraName || verified !== order.verified); 

  }

  function considerEnableUpdateButton() 
  {
    if (order) {
      setThereAreValueChanges(isOrderUpdated());
    }
  }
  
  /*
  const handleRemoveItem = (index) => {
    confirmRemovingItem(index);
  };
  */

  // Confirm removing item
  const confirmRemovingItem = (index) => {
    if (index >= 0) {
      clonedOrderItems.splice(index, 1);
      setFormUpdated(!formUpdated);
      setTotals();
    }
    //showRemoveItemConfirmDialog(-1);
  }

  // Delete order
  const handleDeleteOrder = () => {
    if (!isDeletingOrder) showDeleteOrderConfirmDialog(true);
  }

  // Confirm deleting order
  const confirmDeletingOrder = () => {
    showDeleteOrderConfirmDialog(false);
    if (!isDeletingOrder) adminDeleteOrder(orderId);
  }

  // Process order
  const processOrderHandler = (nextStatus) => {
    if (nextStatus === ORDER_STATUS.SHIPPED) {
      if (thereAreValueChanges) {
        toast.error("There are changes in this order. Please update first");
      } else if (!pallet) {
        toast.error("Please enter pallet size!");
      } else if (deliverType === DELIVERY_TYPE.DELIVER && !selectedDriverId) {
        toast.error("Please select driver!");  
      } else {
        if (deliverType === DELIVERY_TYPE.DELIVER) {
          showShippingConfirmDialog(true);
        } else if (deliverType === DELIVERY_TYPE.PICK_UP) {
          showPickingUpConfirmDialog(true);
        }
      }
    } else if (nextStatus === ORDER_STATUS.DELIVERED) {
      showDeliveryConfirmDialog(true);
    } else {
      // There there are chages, update first
      if (thereAreValueChanges) {
        toast.error("There are changes in this order. Please update first");
      } else {
        const invalidRows = clonedOrderItems.filter(item => item.name && (item.finalQuantity === undefined || item.finalPrice === undefined));
        if (invalidRows && invalidRows.length > 0) {
          toast.error("Please enter price and quantity for all items!");
          return;
        }
         
        const orderData = appendShippingData({ status: nextStatus });
        adminUpdateOrder({ id: orderId, isOldOrder, body: orderData });
      }
    }
  };

  const appendShippingData = (orderData) => {
    if (deliverType && deliverType !== order.deliverType) {
      orderData.deliverType = deliverType;
    }
    if (selectedDriverId && selectedDriverId !== order.driver?._id) {
      orderData.driverId = selectedDriverId;
      orderData.driverName = selectedDriverName;
    }
    if (pallet && pallet !== order.pallet) {
      orderData.pallet = pallet;
    }
    if (shippingFee && shippingFee !== order.shippingFee) {
      orderData.shippingFee = shippingFee;
    }
    return orderData;
  }

  const confirmShippingOrder = () => {
    showShippingConfirmDialog(false);
    let orderData = appendShippingData({ status: ORDER_STATUS.SHIPPED });
    // generate cost of goods sold
    const costs = [];
    clonedOrderItems.forEach((item, index) => {

      if (item.product) {
        const product = inventory[item.product]?.product;
        if (product) {
          const cost = product.cost;
          costs[index] = cost;
        }
      }
    });
    orderData.costs = costs;
    adminShipOrder({ id: orderId, body: orderData });
  }

  const confirmPickupOrder = () => {
    showPickingUpConfirmDialog(false);
    let orderData = appendShippingData({ status: ORDER_STATUS.READY_TO_SHIP });

    // generate cost of goods sold
    const costs = [];
    clonedOrderItems.forEach((item, index) => {
      if (item.product) {
        const product = inventory[item.product]?.product;
        if (product) {
          const cost = product.cost;
          costs[index] = cost;
        }
      }
    });
    orderData.costs = costs;
    adminPickupOrder({ id: orderId, body: orderData });
  }

  // For delivery order
  const confirmDeliverOrder = () => {
    showDeliveryConfirmDialog(false);
    adminDeliverOrder({ id: orderId });
  }

  //For pickup order
  const confirmFinishPickedUpOrder = () => {
    showDeliveryConfirmDialog(false);
    adminFinishPickeDupOrder({ id: orderId });
  }

  // Handle price
  const handlePriceChange = (index, value) => {
    if (valueIsANonNegativeNumber(value) && clonedOrderItems[index].finalPrice !== value) {
      /*
      const product = clonedOrderItems[index].product ? inventory[clonedOrderItems[index].product]?.product : null;
      const { ok, message } = SellPriceDoubleCheck(user, product, value);
      if (!ok) {
        // reset the value in 1 second
        toast.error(message);
        setTimeout(() => {
          setRefreshKey(prevKey => prevKey + 1); // Force re-render
        }, 1000);
      } else 
      */
      {
        clonedOrderItems[index].finalPrice = Number(value);
        setTotals();
        setFormUpdated(!formUpdated);
      }  
    }
  };
    
  // Handle quantity
  const handleQuantityChange = (index, value) => {
    if (valueIsANumber(value) && clonedOrderItems[index].finalQuantity !== value) {
      clonedOrderItems[index].finalQuantity = Number(value);
      setFormUpdated(!formUpdated);
      setTotals();
    }
  };

  const handleNotesChange = (index, value) => {
    if (clonedOrderItems[index].note !== value) {
      clonedOrderItems[index].note = value;
      setFormUpdated(!formUpdated);
    }
  };

  const handleShippingFeeChange = (e) => {
    const value = e.target.value;
    setShippingFee(value);
  };

  const handleShippingFeeBlur = (e) => {
    const value = parseFloat(e.target.value);
    if (!isNaN(value)) {
      setShippingFee(value.toFixed(2));
      setFormUpdated(!formUpdated);
    } else {
      setShippingFee(originalShippingFee);
    }
  };

  // Add item row
  const addItemRowHandler = () => {
    clonedOrderItems.push({});
    setclonedOrderItems(clonedOrderItems);
    setFormUpdated(!formUpdated);
    setTotals();
  };

  const setProduct = (index, product) => {
    const { price : calculatedPrice } = getSellPrice(product, order.customer.tier);
      Object.assign(clonedOrderItems[index], {
        product: product._id,
        name: product.name,
        initQuantity: 1,
        finalQuantity: 1,
        listedPrice: calculatedPrice,
        finalPrice: calculatedPrice, 
      }
    );
    setTotals();
    setFormUpdated(!formUpdated);
  };

  /*
  // Transfer to Quickbooks
  const transferToQuickbooksHandler = () => {
    if (!isTransferingToQuickbooks) {
      if (thereAreValueChanges) {
        toast.error("There are changes in this order. Please update first");
      } else {
        localStorage.setItem('quickbooksAction', 'transferToQuickbooks');
        adminAuthorizeQuickbooks();
      }
    }
  };
  */

  //  --- Print order ---
  const handlePrintOptionSelect = (option) => {
    switch (option) {
      case 'PackingSlip':
        printPackingSlip();
        break;
      case 'Invoice':
        printInvoice();
        break;
      default:
    }
  };

  const printPackingSlip = () => {
    window.open('/admin/orders/' + orderId + '/packingslip', '_blank');
  };

  const printInvoice = () => {
    if (isOldOrder) {
      window.open('/admin/orders/' + orderId + '/oldinvoice', '_blank');
    } else {
      window.open('/admin/orders/' + orderId + '/invoice', '_blank');
    }
  };

  const processEmailCustomerHandler = () => {
    if (thereAreValueChanges) {
      toast.error("There are changes in this order. Please update first");
    } else {
      window.open('/admin/orders/' + orderId + `/email?email=${order.customer.email}&orderNumber=${order.orderNumber}&customerName=${order.customer.name}&orderStatus=${order.status}`, '', 'width=800,height=600');
    }
  };

  const handleDateChange = (date) => {
    date.setHours(0, 0, 0, 0); // Set the time to the start of the day
    const isoDate = date.toISOString(); // Convert to ISO 8601 format
    setOrderDate(isoDate);
    setFormUpdated(!formUpdated);
  };

  const handleCheckBoxChange = (e) => {
    setOrderNoQB(!e.target.checked);
    setFormUpdated(!formUpdated);
  }

  const handleVerified = (e) => {
    setVerified(e.target.checked);
    setFormUpdated(!formUpdated);
  };

  const handleKeyDown = (e) => {
    if (e.key === 'ArrowUp' || e.key === 'ArrowDown') {
      e.preventDefault();
    }
  };

  const UpdateOrderButton = () => {
    return (
      <button
        disabled={!thereAreValueChanges || isLoading || !editable || isUpdatingOrder}
        onClick={() => {
          order.status === ORDER_STATUS.SHIPPED || order.status === ORDER_STATUS.DELIVERED
            ? showUpdateShippedOrderConfirmDialog(true)
            : updateOrderHandler();
        }}
        className="btn btn-success"
        style={{ width: '100%' }}
      >
        Update
      </button>
    );
  };

  // --------------------------------------
  // Determine user rights
  const {rights} = isOldOrder ? DetermineUserOldOrderRights(user, status) : DetermineUserOrderRights(user, status);
  const editable = rights?.some(right => right === ORDER_USER_RIGHTS.UPDATE);
  //const addable = rights?.some(right => right === ORDER_USER_RIGHTS.ADD);
  const deleteable = rights?.some(right => right === ORDER_USER_RIGHTS.DELETE);
  const verifyInvoiceSignature = rights?.some(right => right === ORDER_USER_RIGHTS.VERIFY_INVOICE_SIGNATURE);
  const canDeleteDocument = rights?.some(right => right === ORDER_USER_RIGHTS.DELETE_DOCUMENT);

  const quickbooksTransferred = order?.quickbooks;
  const shippedOrder = status === ORDER_STATUS.SHIPPED || status === ORDER_STATUS.DELIVERED || status === ORDER_STATUS.CANCELLED;
  const MDBDTableClassname = "myMDBDataProcessOrderTableRow";
  const driver = allDriverData?.find(driver => driver._id === order?.driver?._id);
  const selectedDriverName = driver ? driver.name : order.driverName;
  
  const setOrders = () => {
    const items = {
      columns: [
        {
          label: <CenterAlignedCell value={"SKU"}> </CenterAlignedCell>,
          field: "sku",
          sort: "asc",
        },
        {
          label: <CenterAlignedCell value={"Product"}> </CenterAlignedCell>,
          field: "name",
          sort: "asc",
        },
        {
          label: <CenterAlignedCell value={"Note"}> </CenterAlignedCell>,
          field: "note",
          sort: "asc",
        },
        {
          label: <CenterAlignedCell value={"Quantity"}> </CenterAlignedCell>,
          field: "quantity",
          sort: "asc",
        },
        {
          label: <CenterAlignedCell value={"Price"}> </CenterAlignedCell>,
          field: "price",
          sort: "asc",
        },
        {
          label: <CenterAlignedCell value={"Item Total"}> </CenterAlignedCell>,
          field: "itemTotal",
          sort: "asc",
        },
      ],
      rows: [],
    };  

    if (!shippedOrder) {
      items.columns.push(
        /*
        {
          label: <CenterAlignedCell value={"Stock"}> </CenterAlignedCell>,
          field: "stock",
          sort: "asc",
        },
        {
          label: <CenterAlignedCell value={"Inbound"}> </CenterAlignedCell>,
          field: "incommingQuantity",
          sort: "asc",
        },
        {
          label: <CenterAlignedCell value={"Outbound"}> </CenterAlignedCell>,
          field: "outcommingQuantity",
          sort: "asc",
        },
        */
        {
          label: <CenterAlignedCell value={"Balance"}> </CenterAlignedCell>,
          field: "balance",
          sort: "asc",
        },
        {
          label: <CenterAlignedCell value={"Actions"}> </CenterAlignedCell>,
          field: "actions",
          sort: "asc",
        },
      );
    }


    // Clone the inventory object
    let filteredInventory = { ...inventory };
    // Remove entries that are already in clonedOrderItems
    // Only allow adding new items 
    clonedOrderItems.forEach(item => {
        if (item.product in filteredInventory) {
            delete filteredInventory[item.product];
        }
    });

    // when the order is already shipped, each item should have cost and finalPrice
    if (shippedOrder && !editable) {
      clonedOrderItems?.forEach((item) => {
        const productId = item.product;
        const product = inventory[productId];
        const quantity = item.finalQuantity;
        const price = item.finalPrice ? item.finalPrice.toFixed(2) : '';
        //const priceString = price?.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 });
        const itemTotal = parseFloat((Number(price) * Number(quantity)).toFixed(2));
        const itemTotalString = isNaN(itemTotal) ? "" : "$" + itemTotal.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 });   
        const note = item.note;

        // Highlight price cell for Admin by checking the sell price threshold
        // product is an inventory item, product.product is the product object
        let priceBackgroundColor;

        if (item.cost && price &&  price !== '' && price !== undefined && (user.role === USER_ROLES.ADMIN || user.role === USER_ROLES.SUPER_ADMIN)) {
          const sellPriceThreshold = calculateSellPriceThresholds(item.cost, price);
          priceBackgroundColor = sellPriceThreshold === PRICE_THRESHOLD_STATUS.BELOW_COST ? 'red' 
                                  : (sellPriceThreshold === PRICE_THRESHOLD_STATUS.BELOW_TARGET ? 'lightyellow' 
                                    : (sellPriceThreshold === PRICE_THRESHOLD_STATUS.ABOVE_TARGET ? 'lightgreen' : 'transparent'));
        }

        items.rows.push({
          sku: product?.sku,
          className: MDBDTableClassname,
          name: <div>
            <strong>{item.name}</strong>
          </div>,
          note,
          price: <RightAlignedCell backgroundColor={priceBackgroundColor} value={price} > </RightAlignedCell>,
          quantity: <RightAlignedCell value={quantity} > </RightAlignedCell>,
          itemTotal: <RightAlignedCell value={itemTotalString} > </RightAlignedCell>,
        });
      });
    // Not shipped yet
    } else {
      clonedOrderItems?.forEach((item, index) => {
        const productId = item.product;
        const matchingIndex = productId ? orderItems.findIndex(item => item.product === productId) : -1;
        const product = inventory[productId];

        let stock = product ? (product.quantity ? product.quantity : 0) : undefined;
        if (product && productId && tempUpdatedInventory[productId] && tempUpdatedInventory[productId].stockInc) {
          stock += tempUpdatedInventory[productId].stockInc;
        }
        const quantity = item.finalQuantity;
        const note = item.note;
        const incommingQuantity = (inventoryDataPurchasesByProduct && inventoryDataPurchasesByProduct[productId]?.inProgressTotal? inventoryDataPurchasesByProduct[productId]?.inProgressTotal : 0);
        const outcommingQuantity = (inventoryDataOrdersByProduct && inventoryDataOrdersByProduct[productId]?.inProgressTotal? inventoryDataOrdersByProduct[productId]?.inProgressTotal : 0);
        let totalOutQty;
        let balance;
        // Existing item
        if (matchingIndex >= 0) {
          totalOutQty = outcommingQuantity + quantity - orderItems[matchingIndex].finalQuantity
        } else {
          // New item
          if (quantity !== undefined && outcommingQuantity !== undefined) {
            totalOutQty = outcommingQuantity + quantity;
          }
        }
        
        if (stock !== undefined && totalOutQty !== undefined) {
          const balanceVal = stock + incommingQuantity - totalOutQty;
          balance = Number.isInteger(parseFloat(balanceVal)) ? balanceVal : balanceVal.toFixed(2);
        }
        const price = item.finalPrice ? item.finalPrice.toFixed(2) : '';
        const itemTotal = Number(price) * Number(quantity);
        const itemTotalString = isNaN(itemTotal) ? "" : "$" + itemTotal.toFixed(2);
        let priceColor, quantityColor;
        let priceBackgroundColor;

        if (matchingIndex >= 0) {
          if (item?.finalPrice !== orderItems[matchingIndex].finalPrice) {
            priceColor = "red";
          }
          if (item?.finalQuantity !== orderItems[matchingIndex].finalQuantity) {
            quantityColor = "red";
          }
          if (user.role === USER_ROLES.ADMIN) {

          }
        } else {
          quantityColor = priceColor = "red";
        }

        const filteredInventoryWithCurrentProduct = productId && product ? 
            { ...filteredInventory, [productId]: product } 
            : filteredInventory;

        // Highlight price cell for Admin by checking the sell price threshold
        // product is an inventory item, product.product is the product object
        if (product && product.product && price &&  price !== '' && price !== undefined && (user.role === USER_ROLES.ADMIN || user.role === USER_ROLES.SUPER_ADMIN)) {
          const itemCost = product?.product?.cost;
          const sellPriceThreshold = calculateSellPriceThresholds(itemCost, price);
          priceBackgroundColor = sellPriceThreshold === PRICE_THRESHOLD_STATUS.BELOW_COST ? 'red' 
                                  : (sellPriceThreshold === PRICE_THRESHOLD_STATUS.BELOW_TARGET ? 'lightyellow' 
                                    : (sellPriceThreshold === PRICE_THRESHOLD_STATUS.ABOVE_TARGET ? 'lightgreen' : 'transparent'));
          // Change price color if background color is lightred
          if (priceBackgroundColor === 'red' && priceColor === 'red') {
            priceColor = 'yellow';
          }
        }

        items.rows.push({
          className: MDBDTableClassname,
          sku: product ? <CenterAlignedCell value={product.product?.sku} > </CenterAlignedCell> : '',
          name: ProductSelection({ productList: filteredInventoryWithCurrentProduct, 
            defaultItem: { ...item, sku: (product ? product.product?.sku : undefined) },
            onChange: (product) => { setProduct(index, product); },              
          }),
          price: product ? <EditableNumberCell backgroundColor={priceBackgroundColor} key={`${index}`}  readOnly={!editable} value={price} onBlur={(val) => {handlePriceChange(index, val)}} color={priceColor}> </EditableNumberCell> : '',
          note: product ? <EditableNumberCell  key={`${productId}`} readOnly={!editable} type={'text'} value={note} onBlur={(val) => {handleNotesChange(index, val)}}> </EditableNumberCell> : '',
          quantity: product ? <EditableNumberCell readOnly={!editable} value={quantity} onBlur={(val) => {handleQuantityChange(index, val)}} color={quantityColor}> </EditableNumberCell> : '',
          itemTotal: product ? <RightAlignedCell value={itemTotalString} > </RightAlignedCell> : '',
          //stock: product ? <RightAlignedCell value={stock !== undefined ? stock : '-'} > </RightAlignedCell> : '',
          //incommingQuantity: product ? <RightAlignedCell value={incommingQuantity} > </RightAlignedCell> : '',
          //outcommingQuantity: product ? <RightAlignedCell value={totalOutQty} > </RightAlignedCell> : '',
          balance:  product ? <span style={{ color: balance < 0 ? "red" : "black"}}><RightAlignedCell value={balance !== undefined ? balance : '_'} ></RightAlignedCell> </span> : '',
          actions: (<center>
                      {editable && index < clonedOrderItems.length - 1 && (<ButtonCell buttonType={"btn btn-outline-danger ms-2"} labelType={"fas fa-trash"} action={(e) => {confirmRemovingItem(index);}} > </ButtonCell>)}                 
                    </center>),
        });
      });
    }
    return items;
  };
  //const creattionTime = new Date(order.createdAt).toLocaleString() ;

  return (
    <>
      <MetaData title={"Order Details"} />
      <HeaderAdmin title={"Order Details"} bg_color={"lightgreen"}/>
      <AdminLayout  menuItem={ADMIN_MENU_ITEMS.ORDERS.name}>
        <div id = "order_table" className="col-11">
          {/* Order # & Delete Order button */}
          <div className="row my-2">
            <div className="col-2 width-100">
              <DatePicker
                selected={orderDate}
                onChange={(date) => { handleDateChange(date); } }
                dateFormat="MM/dd/yyyy"
                className="form-control"
              />
            </div>
            {/* Order # */}
            <div className="col-7 d-flex align-items-center justify-content-center">
              <h4 className="my-1 text-center"> 
                Order#: <strong>{order.orderNumber} </strong>
              </h4>
              {/* Print Order */}
              <div className="ms-4 dropdown">
                <button
                  type="button"
                  id="dropDownMenuButton"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                  style={{ height: '2.5em', verticalAlign: 'middle', width: '2.5em', padding: '0.5em' }}
                >
                  <i className={"fas fa-print"}/>
                </button>
                <div
                  className="dropdown-menu w-100"
                  aria-labelledby="dropDownMenuButton"
                >
                  { !isOldOrder && (
                    <Link to="#" className="dropdown-item" style={{ textDecoration: 'none' }} onClick={(e) => { e.preventDefault(); handlePrintOptionSelect('PackingSlip'); }}>Packing Slip</Link>
                  )}
                  <Link to="#" className="dropdown-item" style={{ textDecoration: 'none' }} onClick={(e) => { e.preventDefault(); handlePrintOptionSelect('Invoice'); }}>Invoice</Link>
                </div>
              </div>
              {/* Email Order */}
              <div className="ms-4">
                <button
                  type="button"
                  id="emailButton"
                  onClick={() => processEmailCustomerHandler()}
                  style={{ height: '2.5em', verticalAlign: 'middle', width: '2.5em', padding: '0.5em' }}
                >
                  <i className={"fas fa-envelope"}/>
                </button>
              </div>

              {/*
              <div className="ms-4">
                <button
                  type="button"
                  id="emailButton"
                  onClick={() => displayQuickbooksStatus()}
                >
                  <img
                    src={quickbooksTransferred === QUICKBOOKS_STATUS.ERROR ? "/images/icons/quickbooks_icon_red.jpg" : "/images/icons/quickbooks_icon.png"}
                    alt="QuickBooks Icon"
                    style={{ height: '2.5em', verticalAlign: 'middle', filter: quickbooksTransferred === QUICKBOOKS_STATUS.NOT_SYNCED ? 'grayscale(100%)' : 'grayscale(0%)' }}
                  />
                </button>
              </div>
              */}
            </div>
            {/* Remove Order  - disabled now*/}
        
            <div className="col-2  offset-1 text-end">
              {deleteable && (<ButtonCell buttonType={"btn btn-outline-danger ms-2"} disabled={ isLoading || showingDeleteOrderConfirm} label={'Delete Order'} action={(e) => {handleDeleteOrder();}} width={'100%'} > </ButtonCell>)}  
            </div>
            
            {/*
            <div className="col-2  offset-1 text-end">
              <text>{`Creation time:  ${creattionTime}`}</text>
            </div>
            */}    
          </div>
          
          {/* Customer */}
          <div className="row my-0 ">
            <div className="col-10 my-0">
              <h5><strong>{order.customerName ? order.customerName : order.customer?.name}</strong> - Tier: {order.customer?.tier}</h5>      
              <h5>Phone: {order.customer?.addressInfo?.phone} - Address: {order.customer?.addressInfo?.address}</h5>
            </div>
            <div className="col-2 my-0">
              <h5 className="my-1 text-left col-12"> Track Quickbooks </h5>
              <input style={{width: '2em', height: '2em'}} type="checkbox" checked={!orderNoQB} onChange={handleCheckBoxChange}></input>
            </div>
          </div>

          <div className="row my-2">
            <div className="col-2 text-end">
              <h5>{`Extra Info: `}</h5>
            </div>
            <div className="my-1 text-left col-2">
              { editable ? (
                <input value={extraName} onChange={(e) => setExtraName(e.target.value)} className="form-control" />
              ) : (
                <h5>{extraName}</h5>
              )}
            </div>
          </div>

          {/* Status*/}
          <div className="row my-2">
            <div className="col-2 text-end">
              <h5><span>{`Status: `}  </span> </h5>
            </div>
            <div className="col-2">
              <h5>
                <strong style={{ color: ORDER_STATUS_ICON[status]?.color }}>{`${String(status === ORDER_STATUS.SHIPPED && deliverType === DELIVERY_TYPE.PICK_UP ? 'PICKED UP' : (status === ORDER_STATUS.DELIVERED && deliverType === DELIVERY_TYPE.PICK_UP ? 'FINISHED' :  status)).toUpperCase()} `}</strong>
                { ORDER_STATUS_ICON[status] && (<i className={ORDER_STATUS_ICON[status]?.icon}  style={{ color: ORDER_STATUS_ICON[status].color }} /> )}
              </h5>
            </div>    
            <div className="col-2 offset-6">
              {rights?.some(right => right === ORDER_USER_RIGHTS.CONFIRM) && <ButtonCell buttonType={"btn btn-primary ms-2"} label={'Confirm'} action={()=>{processOrderHandler(ORDER_STATUS.CONFIRMED)}} disabled={isLoading} width={'100%'} >  </ButtonCell>}
              {rights?.some(right => right === ORDER_USER_RIGHTS.PACK) && <ButtonCell buttonType={"btn btn-primary ms-2"} label={'Pack'} action={()=>{processOrderHandler(ORDER_STATUS.PACKING)}} disabled={isLoading} width={'100%'}>  </ButtonCell>}
              {rights?.some(right => right === ORDER_USER_RIGHTS.FINISH_PACKING) && <ButtonCell buttonType={"btn btn-primary ms-2"} label={'Finish Packing'} action={()=>{processOrderHandler(ORDER_STATUS.READY_TO_SHIP)}} disabled={isLoading} width={'100%'}>  </ButtonCell>}  
              {rights?.some(right => right === ORDER_USER_RIGHTS.SHIP) && <ButtonCell buttonType={"btn btn-primary ms-2"} label={deliverType === DELIVERY_TYPE.DELIVER ? 'Ship' : 'Pick Up'} action={()=>{processOrderHandler(ORDER_STATUS.SHIPPED)}} disabled={isLoading} width={'100%'}>  </ButtonCell>}
              {rights?.some(right => right === ORDER_USER_RIGHTS.DELIVER) && <ButtonCell buttonType={"btn btn-primary ms-2"} label={deliverType === DELIVERY_TYPE.DELIVER ? 'Deliver' : 'Finish'} action={()=>{processOrderHandler(ORDER_STATUS.DELIVERED)}} disabled={isLoading} width={'100%'}>  </ButtonCell>}
            </div>  
          </div>
          {/* Loader */}
          {isLoading && (
            <div style={{position: 'fixed', top: '50%', left: '58%', transform: 'translate(-50%, -50%)', zIndex: 9999}}>
              <Loader />
            </div>
          )}
          {/* Pallet size */}
          {(order.status === ORDER_STATUS.READY_TO_SHIP || order.status === ORDER_STATUS.SHIPPED || order.status === ORDER_STATUS.DELIVERED) && (
            <div className="row my-2">
              {/* Pallet size */}
              <div className="col-2 text-end">
                <h5>Pallet Size: </h5>
              </div>
              <div className="col-2">
                <h5>{pallet}</h5>
              </div>
              {/* Shipping options */}
              <div className="col-2 text-end">
                  <h5>Shipping: </h5>
              </div>
              <div className="col-2">
                <select className="form-select" value={deliverType} onChange={(e) => { setDeliverType(e.target.value); setFormUpdated(!formUpdated); }} disabled={!editable}>
                  {Object.entries(DELIVERY_TYPE).map(([key, value]) => (
                    <option key={key} value={value}>{String(value).toUpperCase()}</option>
                  ))}
                </select>
              </div>
            </div>
          )}
           

          {(order.status === ORDER_STATUS.READY_TO_SHIP || order.status === ORDER_STATUS.SHIPPED || order.status === ORDER_STATUS.DELIVERED) && deliverType === DELIVERY_TYPE.DELIVER && (
            <div className="row my-2">
              <div className="col-2 text-end">
                <h5>Driver: </h5>
              </div>
              <div className="col-2">
                {isOldOrder ? (
                  <h5>{selectedDriverName}</h5>
                ) : (
                <select className="form-select"  defaultValue="" value={selectedDriverId} onChange={(e) => { setSelectedDriverId(e.target.value); setFormUpdated(!formUpdated); }} disabled={!editable}>
                  <option value="" disabled="true">Select Driver</option>
                  {allDriverData && allDriverData.map(driver => (
                    <option key={driver._id} value={driver._id}>{driver.name}</option>
                  ))}
                </select>
                )}
              </div>
              <div className="col-2" style={{textAlign: 'right'}}>
                <h5>Shipping Fee: </h5>
              </div>
              <div className="col-2">
                <input onWheel={(e) => e.target.blur()} onKeyDown={handleKeyDown} type="number" step="0.01" className="form-control no-arrows text-end" value={shippingFee} onChange={(e) => handleShippingFeeChange(e)} onBlur={(e) => {handleShippingFeeBlur(e)}} disabled={!editable}/>
              </div>
            </div>
          )}       

          {/* Terms, PO, Sale Rep */}
          <div className="row my-2">
            <div className="col-2 text-end">
              <h5>P.O. Number: </h5>
            </div>
            <div className="col-2">
              <input type="text" className="form-control" value={PONumber} onChange={(e) => {setPONumber(e.target.value); setFormUpdated(!formUpdated);}} disabled={!editable}/>
            </div>

            <div className="col-2 text-end">
              <h5>Terms: </h5>
            </div>
            <div className="col-2">
              <input type="text" className="form-control" value={terms} onChange={(e) => {setTerms(e.target.value); setFormUpdated(!formUpdated);}} disabled={!editable}/>
            </div>
            <div className="col-2 text-end">
              <h5>Sales Rep.: </h5>
            </div>
            <div className="col-2">
              <input type="text" className="form-control" value={salesRep} onChange={(e) => {setSalesRep(e.target.value); setFormUpdated(!formUpdated);}} disabled={!editable}/>
            </div>
          </div>

          {/* Add a thin horizontal line */}
          <hr style={{ borderTop: '1px solid #ccc' }} />

          {/* Handler */}
          <div className="row my-2">
            {/* Created by */}
            {order.createdBy && (
              <div className="col-2 text-end">
                <h5><span>{'Created by: '}</span> </h5>
              </div>
            )}
            {order.createdBy && (
              <div className="col-2">
                <h5><span><strong>{order.createdBy}</strong></span> </h5>
              </div>
            )}

            {/* Confirmed by */}
            { order.confirmedBy && (
              <div className="col-2 text-end">
                <h5><span>{'Confirmed by: '}</span> </h5>
              </div>
            )}
            { order.confirmedBy && (
              <div className="col-2">
                <h5><span><strong>{order.confirmedBy}</strong></span> </h5>
              </div>
            )}

            {/* Packing by */}
            {order.packedBy && order.status === ORDER_STATUS.PACKING && (
              <div className="col-2 text-end">
                <h5><span>{'Packing by: '}</span> </h5>
              </div>            
            )}        
            {order.packedBy && order.status === ORDER_STATUS.PACKING && (
              <div className="col-2">
                <h5><span><strong>{order.packedBy}</strong></span> </h5>
              </div>            
            )}      

            {/* Packed by */} 
            {order.packedBy && order.status !== ORDER_STATUS.PACKING && (
              <div className="col-2 text-end">
                <h5><span>{'Packed by: '}</span> </h5>
              </div>            
            )} 
            {order.packedBy && order.status !== ORDER_STATUS.PACKING && (
              <div className="col-2">
                <h5><span><strong>{order.packedBy}</strong></span> </h5>
              </div>            
            )} 

            {/* Shipped by */}
            {order.driver && order.status === ORDER_STATUS.SHIPPED && (
              <div className="col-2 text-end">
                <h5><span>{'Shipped by: '}</span> </h5>
              </div>            
            )} 
            {order.driver && order.status === ORDER_STATUS.SHIPPED && (
              <div className="col-2">
                <h5><span><strong>{order.driver.name}</strong></span> </h5>
              </div>            
            )} 

            {/* Delivered by */}
            {order.driver && order.status !== ORDER_STATUS.SHIPPED && (
              <div className="col-2 text-end">
                <h5><span>{'Delivered by: '}</span> </h5>
              </div>            
            )} 
            {order.driver && order.status !== ORDER_STATUS.SHIPPED && (
              <div className="col-2">
                <h5><span><strong>{order.driver.name}</strong></span> </h5>
              </div>            
            )} 
          </div>

          {/* Order Items */}
          <MDBDataTable
            data={setOrders()}
            className={`my-0 px-10 ${MDBDTableClassname}`}
            bordered
            small
            hover
            noBottomColumns
            noRecordsFoundLabel={"No items found"}
            displayEntries={false}
            searchLabel={"Filter"}
            paging={false}
            striped
          />
          <div className="row my-0">
            <div className="col-7 col-lg-7 my-4 offset-0">
              <label htmlFor="notes_field">Notes</label>
              <textarea
                className="form-control"
                value={notes}
                style={{ height: '200px' }}
                onChange={(e) => { setNotes(e.target.value); }}
              />
              <label htmlFor="history_field">History</label>
              <textarea
                className="form-control"
                value={history}
                wrap="soft"
                style={{ 
                  height: '200px', 
                  overflow: 'auto',
                  whiteSpace: 'pre'
                }}
                readOnly
              />
            </div>
            <div className="col-4 col-lg-4 my-0 offset-1">
              {/* Update Button */}
              {editable && (
              <div className="my-4">
                <UpdateOrderButton />
              </div>)}

              {/* Order Summary */} 
              <div>
                <div id="order_summary">
                  <h4>Order Summary</h4>
                  <hr />
                  <p>
                    Units:{" "}
                    <span className="order-summary-values">
                      {totalValues?.units} (Units)
                    </span>
                  </p>
                  <p>
                    Est. size:{" "}
                    <span className="order-summary-values">
                      {totalValues?.estPallet?.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })} (Pallet)
                    </span>
                  </p>
                  <hr />
                  <p>
                    Item Total:{" "}
                    <span className="order-summary-values">
                      $
                      {totalValues?.itemTotal?.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
                    </span>
                  </p>      
                  <p>
                    Tax:{" "}
                    <span className="order-summary-values">
                      $
                      {totalValues?.tax?.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
                    </span>
                  </p>      
                  <hr />
                  <p>
                    <strong>Total:{" "}</strong>
                    <span className="order-summary-values">
                      $
                      {totalValues?.total?.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
                    </span>
                  </p>            
                  <hr />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="row col-11">
          <div className="col-8 my-4">
            <form className="mt-4" onSubmit={submitUploadImageHandler}>
              {/* Upload images*/}
              <div className="my-4 ">
                <div className="row col-6">
                  <div className="col-6">
                    <h3 className="mb-4">Delivery Slip</h3>
                  </div>
                  {(uploadedImages && uploadedImages?.length > 0) && (
                    <div className="row col-6">
                      <div col-2>
                        <input
                          style={{ width: '1.5em', height: '1.5em' }}
                          type={'checkbox'}
                          checked={verified}
                          onChange={(e) => { handleVerified(e); }}
                        />
                      </div>
                    </div>
                  )}
                </div>
                { (!uploadedImages || uploadedImages?.length === 0) && (
                  <div className="mb-3">
                    <label htmlFor="customFile" className="form-label">
                      Choose Files
                    </label>

                    <div className="row align-items-center">
                      {/* Select file */}
                      <div className="col-6 col-lg-6">
                        <input
                          ref={fileInputRef}
                          type="file"
                          name="product_images"
                          className="form-control"
                          id="customFile"
                          multiple
                          onChange={onChangeImageFiles}
                          onClick={handleResetFileInput}
                        />
                      </div>
                      {/* Upload button */}
                      <div className="col-2 col-lg-2 offset-1">
                        <button
                          id="register_button"
                          type="submit"
                          className="btn btn-success ms-2 w-100"
                          disabled={isLoading || !selectedFile || isUpdatingOrder}
                        >
                          {isUploadingImages ? "Uploading..." : "Upload"}
                        </button>
                      </div>
                    </div>
                  </div>
                )}
                {imagesPreview && (
                  <div className="new-images my-4">
                    <p className="text-warning">New Document:</p>
                    <div className="row mt-4">
                      <div className="col-md-3 mt-2">
                        <div className="card">
                          {/*
                          <input
                            type="text"
                            value={newDocumentName}
                            onChange={(e) => handleDocumentNameChange(e)}
                            placeholder="Enter document name"
                            className="form-control"
                          />
                          */}
                          <img
                            src={imagesPreview}
                            alt="Card"
                            className="card-img-top p-2"
                            style={{ width: "auto", height: "80px", objectFit: "contain" }}
                          />
                          <button
                            style={{
                              backgroundColor: "#dc3545",
                              borderColor: "#dc3545",
                            }}
                            type="button"
                            className="btn btn-block btn-danger cross-button mt-1 py-0"
                            onClick={() => handleImagePreviewDelete()}
                          >
                            <i className="fa fa-times"></i>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              
                {/* Uploaded images */}
                {uploadedImages?.length > 0 && (
                  <div className="uploaded-images my-4">
                    <p className="text-success">Uploaded Document:</p>
                    <div className="row mt-1">
                      {uploadedImages?.map((img) => (
                        <div className="col-md-2 mt-2">
                          <div className="card" style={{ display: "flex", justifyContent: "center" }}>
                            <p className="card-text">{img?.name ?? '\u00A0'}</p>
                            <a href={`/admin/orders/viewdocument/${img?.public_id}`} target="_blank" rel="noopener noreferrer" style={{ textDecoration: "none", display: "flex", justifyContent: "center" }}>
                              <img
                                src="/images/magnifier.png"
                                alt="Card"
                                className="card-img-top p-2"
                                style={{ width: "auto", height: "80px", objectFit: "contain" }}
                              />
                            </a>
                            {canDeleteDocument && (
                              <button
                                style={{
                                  backgroundColor: "#dc3545",
                                  borderColor: "#dc3545",
                                  padding: "2px 5px",
                                  fontSize: "12px",
                                }}
                                className="btn btn-block btn-danger cross-button mt-1 py-0"
                                type="button"
                                disabled={isLoading || isUpdatingOrder }
                                onClick={() => setImageIdToDelete(img.public_id)}
                              >
                                <i className="fa fa-trash fa-sm" style={{ fontSize: "0.8em" }}></i>
                              </button>
                            )}
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                )}
              </div>
            </form>
          </div>
          {/* Update Button 2 - same function as button 1*/}
          {/*editable && (
            <div className="my-4 col-4">
              <UpdateOrderButton />
            </div>
          )*/}
        </div>
        {/* End of Upload images */}
        <ConfirmDialog message={`Are you sure to remove ${clonedOrderItems[itemIndexToRemove] ? clonedOrderItems[itemIndexToRemove].name :"row"}?`} show={itemIndexToRemove !== -1} confirm={()=>{confirmRemovingItem(itemIndexToRemove)}} cancel={()=> {showRemoveItemConfirmDialog(-1)}}> </ConfirmDialog>
        <ConfirmDialog message={`Are you sure to delete this order?`} show={showingDeleteOrderConfirm} confirm={()=>{confirmDeletingOrder()}} cancel={()=> {showDeleteOrderConfirmDialog(false)}}> </ConfirmDialog>
        <ConfirmDialog message={`Are you sure to update a delivered order? Make sure to update on Quickbooks app too!`} show={showingUpdateShippedOrderConfirm} confirm={()=>{updateOrderHandler(); showUpdateShippedOrderConfirmDialog(false); }} cancel={()=> {showUpdateShippedOrderConfirmDialog(false)}}> </ConfirmDialog>
        <ConfirmDialog message={`Are you sure to delete this document?`} show={imageIdToDelete != null} confirm={()=>{confirmDeletingDocument()}} cancel={()=> {setImageIdToDelete(null)}}> </ConfirmDialog>
        <ConfirmDialogShippingOrder 
                       title={`Ship Order #${order.orderNumber}`}
                       show={showingShippingConfirm} 
                       deliverType={deliverType}
                       selectedDriver={selectedDriverName}
                       pallet={pallet}
                       confirm={()=>{confirmShippingOrder()}} 
                       cancel={()=> {showShippingConfirmDialog(false)}}> 
        </ConfirmDialogShippingOrder>
        <ConfirmDialogShippingOrder 
                       title={`Pick Up Order #${order.orderNumber}`}
                       show={showingPickingUpConfirm} 
                       deliverType={deliverType}
                       pallet={pallet}
                       confirm={()=>{confirmPickupOrder()}} 
                       cancel={()=> {showPickingUpConfirmDialog(false)}}> 
        </ConfirmDialogShippingOrder>
        <ConfirmDialog message={deliverType === DELIVERY_TYPE.DELIVER ? `Deliver order #${order.orderNumber}? Make sure you upload delivery slip first.` : `Finish order #${order.orderNumber}? Make sure you upload signed invoice first.`} show={showingDeliveryConfirm} confirm={()=>{deliverType === DELIVERY_TYPE.DELIVER ? confirmDeliverOrder() : confirmFinishPickedUpOrder() }} cancel={()=> {showDeliveryConfirmDialog(false)}}> </ConfirmDialog>
      </AdminLayout>
    </>
  );
};

export default ProcessOrder;
