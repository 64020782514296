import React, { useEffect, useState } from "react";
import MetaData from "../layout/MetaData";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { setCartItem, removeCartItem } from "../../redux/features/cartSlice";
import Header from "../layout/Header";
import { CalculateOrderSummary } from "../../utils/utilities";

const Cart = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  
  const { cartItems } = useSelector((state) => state.cart);

  const [cartEmpty, setCartEmty] = useState(false);

  useEffect(() => {
    const itemCountTotal = cartItems?.reduce((acc, item) => acc + item.finalQuantity, 0);
    setCartEmty(itemCountTotal === 0);
  }, [cartItems]);

  const increaseQty = (item, quantity) => {
    const newQty = (!isNaN(quantity) ? Number(quantity) : 0) + 1;
    setItemToCart(item, newQty);
  };

  const decreseQty = (item, quantity) => {
    let newQty = (!isNaN(quantity) ? Number(quantity) : 0) - 1;
    if (newQty < 0) 
      newQty = 0;
    setItemToCart(item, newQty);
  };
  
  const updateQty = (item, quantity) => {
    if (isNaN(quantity) || quantity < 0) 
      quantity = 0;
    setItemToCart(item, Number(quantity));
  };
  
  const setItemToCart = (item, newQty) => {
    const cartItem = {
      product: item?.product,
      name: item?.name,
      finalPrice: item?.finalPrice,
      image: item?.image,
      stock: item?.stock,
      finalQuantity: newQty,
      palletSize: item?.palletSize,
    };

    dispatch(setCartItem(cartItem));
  };

  const removeCartItemHandler = (id) => {
    dispatch(removeCartItem(id));
  };

  const checkoutHandler = () => {
    navigate("/shipping");
  };

 const { units, estPallet, itemTotal } = CalculateOrderSummary(cartItems);

  
  return (
    <>
      <MetaData title={"Your Cart"} />
      <Header title={"Your Cart"} />
      <div className="container">
        {cartItems?.length === 0 ? (
          <h2 className="mt-5">Your Cart is Empty</h2>
        ) : (
          <>
            <h2 className="mt-5">
              <b>{cartItems?.length} items</b>
            </h2>

            <div className="row d-flex justify-content-between">
              <div className="col-12 col-lg-8">
              <table className="table">
                <thead>
                  <tr>
                  <th scope="col" style={{ textAlign: 'center', fontSize: '1.25rem' }}></th>
                  <th scope="col" style={{ textAlign: 'center', fontSize: '1.25rem' }}>Name</th>
                  <th scope="col" style={{ textAlign: 'center', fontSize: '1.25rem' }}>Price</th>
                  <th scope="col" style={{ textAlign: 'center', fontSize: '1.25rem' }}>Quantity</th>
                  <th scope="col" style={{ textAlign: 'center', fontSize: '1.25rem' }}>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {cartItems?.map((item) => (
                    <>
                      <tr key={item?.product}>
                        <td style={{ verticalAlign: 'middle', textAlign: 'center' }}>
                        {item.image ? (
                          <img
                            src={item.image}
                            alt="Laptop"
                            height="60"
                            width="60"
                          />
                        ) : (
                          <div style={{ height: '60px', width: '60px' }}></div>
                        )}
                        </td>
                        <td style={{ verticalAlign: 'middle', textAlign: 'center' }}>
                          <Link to={`/products/${item.product}`}>
                            {item.name}
                          </Link>
                        </td>
                        <td style={{ verticalAlign: 'middle', textAlign: 'center' }}>
                          <p id="card_item_price">
                            {item.finalPrice ? `$${item.finalPrice?.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}` : 'Call'}
                          </p>
                        </td>
                        <td style={{ verticalAlign: 'middle', textAlign: 'center' }}>
                          <div className="stockCounter d-inline">
                            <span
                              className="btn btn-danger minus"
                              onClick={() => decreseQty(item, item.finalQuantity)}
                            >
                              -
                            </span>
                            <input
                              type="number"
                              className="form-control count d-inline"
                              value={item?.finalQuantity}
                              style={{ width: '4vw', marginLeft: '10px', marginRight: '10px' }}
                              onChange={(e) => updateQty(item, e.target.value)}
                              onWheel={(e) => e.target.blur()}
                            />
                            <span
                              className="btn btn-primary plus"
                              onClick={() => increaseQty(item, item.finalQuantity)}
                            >
                              +
                            </span>
                          </div>
                        </td>
                        <td style={{ verticalAlign: 'middle', textAlign: 'center' }}>
                          <i
                            id="delete_cart_item"
                            className="fa fa-trash btn btn-danger"
                            onClick={() => removeCartItemHandler(item?.product)}
                          ></i>
                        </td>
                      </tr>
                    </>
                  ))}
                </tbody>
              </table>
              </div>

              <div className="col-12 col-lg-3 my-4">
                <div id="order_summary">
                  <h4>Order Summary</h4>
                  <hr />
                  <p>
                    Units:{" "}
                    <span className="order-summary-values">
                      {units}{" "}(Units)
                    </span>
                  </p>
                  {
                  estPallet > 0 && (
                  <p>
                    Est. Size:{" "}
                    <span className="order-summary-values">
                      {estPallet?.toFixed(2)} (Pallet)
                    </span>
                  </p>)
                  }
                  <hr />           
                         
                  <p>
                    Est. total:{" "}
                    <span className="order-summary-values">
                      {itemTotal !== undefined ? `$${itemTotal.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}` : "Call"}
                    </span>
                  </p>

                  <hr />
                  <button
                    id="checkout_btn"
                    disabled={cartEmpty}
                    className="btn btn-primary w-100"
                    onClick={checkoutHandler}
                  >
                    Check out
                  </button>
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default Cart;
