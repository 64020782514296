import React, { useEffect, useState, useRef } from "react";
import { toast } from "react-hot-toast";
import { useSelector } from "react-redux";
import { useParams, useNavigate } from "react-router-dom";

import Loader from "../../layout/Loader";
import MetaData from "../../layout/MetaData";
import AdminLayout from "../../layout/AdminLayout";
import { CategorySelection } from "../../product/CategorySelection";
import {
  useAdminUpdateProductMutation,
  useAdminGetProductDetailsQuery,
  useAdminDeleteProductImageMutation,
  useAdminUploadProductImagesMutation,
} from "../../../redux/api/productsAdminApi";
import { useLazyAdminLogoutQuery } from "../../../redux/api/adminAuthApi";
import { useAdminGetVendorProductPriceListQuery } from "../../../redux/api/priceListAdminApi";
import HeaderAdmin from "../../layout/HeaderAdmin";
import { ADMIN_MENU_ITEMS } from "../../../constants/constants.js";
import { CanUpdateProduct } from "../AdminActionEligibilities";

const UpdateProduct = () => {
  const navigate = useNavigate();
  const params = useParams();

  // States
  const [product, setProduct] = useState({
    sku: "",
    name: "",
    otherName: "",
    category: "",
    description: "",
    price: "",
    origin: "",
    weight: "",
    palletSize: "",
    isActive: true,
  });
  const [clonedProduct, setClonedProduct] = useState({});

  const { loadingAdmin, user } = useSelector((state) => state.adminAuth);
  const [showVendorPrices, setShowVendorPrices] = useState(false);

  // Update product details
  const { sku, name, otherName, category, description, price, origin, weight, palletSize, isActive } = product;
  const [updateProduct, { isLoading: isUpdating, error, isSuccess }] = useAdminUpdateProductMutation();
  const { data: productDetails, isLoading, refetch: refetchProductDetails } = useAdminGetProductDetailsQuery(params?.id);
  const [adminLogout] = useLazyAdminLogoutQuery();

  // Update image
  const fileInputRef = useRef(null);
  const [imagesPreview, setImagesPreview] = useState([]);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [uploadedImages, setUploadedImages] = useState([]);

  const [uploadProductImages, { isLoading: isUploadingImages, error: errorUploadingImages, isSuccess: uploadImagesSuccess }] = useAdminUploadProductImagesMutation();
  const [deleteProductImage, { isLoading: isDeletingImage, error: deleteError, isSuccess: deleteImageSuccess },] = useAdminDeleteProductImageMutation();
  const { data: vendorProductPriceListData, isLoading: loadingProductPriceListData, refetch: refetchProductPriceList } = useAdminGetVendorProductPriceListQuery(params?.id);

  useEffect(() => {
    // Force refetch when the component mounts
    if (refetchProductDetails) {
      refetchProductDetails({ force: true });
    }
    if (refetchProductPriceList) {
      refetchProductPriceList({ force: true });
    }
  }, [refetchProductDetails, refetchProductPriceList]);

  useEffect(() => {
    if (errorUploadingImages) {
      toast.error(errorUploadingImages?.data?.message);
    }
    if (deleteError) {
      toast.error(deleteError?.data?.message);
    }
  }, [errorUploadingImages, deleteError]);

  useEffect(() => {
    if (uploadImagesSuccess) {
      setImagesPreview([]);
      toast.success("Image(s) Uploaded");
    }
  }, [uploadImagesSuccess]);

  useEffect(() => {
    if (deleteImageSuccess) {
      toast.success("Image Removed");
    }
  }, [deleteImageSuccess]);

  useEffect(() => {
    if (productDetails) {
      setUploadedImages(productDetails.images);
      const newProduct = {
        sku: productDetails.sku,
        name: productDetails.name,
        otherName: productDetails.otherName,
        category: productDetails.category,
        description: productDetails.description,
        price: Number(productDetails.price),
        origin: productDetails.origin,
        palletSize: productDetails.palletSize,
        weight: productDetails.weight,
        isActive: productDetails.isActive,
        cost: productDetails.cost,
      };
      setProduct(newProduct);
      setClonedProduct(newProduct);
    }
  }, [productDetails]);

  useEffect(() => {
    if (isSuccess) {
      toast.success("Product updated");
      //navigate("/admin/products");
    }
    if (error) {
      toast.error(error?.data?.message);
      if (error?.status === 401) {
        adminLogout();
      }
    }
  }, [error, isSuccess]);

  const onChange = (e) => {
    setProduct({ ...product, [e.target.name]: e.target.value });
  };

  const onChangeNumber = (e) => {
    const value = e.target.value;
    setProduct({ ...product, [e.target.name]: value });
  };

  const handleFocus = () => {
    setShowVendorPrices(true); // Show vendor prices when input is focused
  };

  const handleBlur = () => {
    setTimeout(() => setShowVendorPrices(false), 200); // Hide vendor prices when input loses focus
  };

  const submitHandler = (e) => {
    e.preventDefault();
    updateProduct({ id: params?.id, body: product });
  };

  const isProductChanged = () => {
    return JSON.stringify(product) !== JSON.stringify(clonedProduct);
  };

  // ----------------- Upload image --------------------------------
  const onChangeImageFiles = (e) => {
    const files = Array.from(e.target.files);

    setSelectedFiles(files); // Store the selected files in the state

    files.forEach((file) => {
      const reader = new FileReader();

      reader.onload = () => {
        if (reader.readyState === 2) {
          setImagesPreview((oldArray) => [...oldArray, reader.result]);
        }
      };

      reader.readAsDataURL(file);
    });
  };

  const handleResetFileInput = () => {
    if (fileInputRef.current) {
      fileInputRef.current.value = "";
    }
  };

  const handleImagePreviewDelete = (image) => {
    const filteredImagesPreview = imagesPreview.filter((img) => img !== image);

    setImagesPreview(filteredImagesPreview);
  };

  const submitUploadImageHandler = (e) => {
    e.preventDefault(); 

    try {
      const formData = new FormData();
      selectedFiles.forEach((file) => {
        formData.append('files', file);
      });

      uploadProductImages({ id: params?.id, formData });
    } catch (error) {
      console.error('Error uploading images:', error);
      toast.error('Failed to upload images');
    }
  };

  const deleteImage = (imgId) => {
    deleteProductImage({ id: params?.id, body: { imgId } });
  };
  //--------------------------------------------------------------

  if (isLoading || loadingAdmin || !product || !vendorProductPriceListData) return <Loader />;

  const disableAllButtons = isLoading || isUploadingImages || isDeletingImage || isUpdating;
  const canUpdateProduct = CanUpdateProduct(user);

  console.log("vendorProductPriceListData", vendorProductPriceListData)

  return (
    <>
      <MetaData title={"Product Details"} />
      <HeaderAdmin title={"Product Details"} />
      <AdminLayout menuItem={ADMIN_MENU_ITEMS.PRODUCTS.name}>
        <div className="row wrapper">
          <div className="col-10 col-lg-10 mt-1 mt-lg-0">
            <form className="shadow rounded bg-body" onSubmit={submitHandler}>
              <div className="row my-0">
                <div className="col-4">
                  <label htmlFor="sku_field" className="form-label">
                    {" "}
                    SKU{" "}
                  </label>
                  <input
                    type="text"
                    id="sku_field"
                    className="form-control"
                    name="sku"
                    readOnly={!canUpdateProduct}
                    value={sku}
                    onChange={onChange}
                  />
                </div>
                <div className="col-5 offset-2">
                  <h3 className="mb-4">{`Active:  `} 
                    <input 
                      type="checkbox" 
                      name="isActive" 
                      checked={isActive} 
                      disabled={!canUpdateProduct}
                      onChange={(e) => {setProduct({ ...product, isActive: e.target.checked });}} 
                      style={{ 
                        transform: 'scale(1.2)', 
                        marginRight: '20px',
                        marginLeft: '20px',
                      }} 
                    /> 
                  </h3>
                </div>           
              </div>
              <div className="row my-4">
                <div className="col-6 col-lg-6">
                  <label htmlFor="name_field" className="form-label">
                    {" "}
                    Name{" "}
                  </label>
                  <input
                    type="text"
                    id="name_field"
                    className="form-control"
                    name="name"
                    readOnly={!canUpdateProduct}
                    value={name}
                    onChange={onChange}
                  />
                </div>
                <div className="col-6 col-lg-6">
                  <label htmlFor="name_field" className="form-label">
                    {" "}
                    Other Name{" "}
                  </label>
                  <input
                    type="text"
                    id="other_name_field"
                    className="form-control"
                    readOnly={!canUpdateProduct}
                    name="otherName"
                    value={otherName}
                    onChange={onChange}
                  />
                </div>
              </div>  
              <div className="row my-4">
                <div className="col-6 col-lg-6">
                  <label htmlFor="category_field" className="form-label">
                    {" "}
                    Category{" "}
                  </label>
                  <CategorySelection value={category} onChange={(selection) => {setProduct({ ...product, category: selection });}} />  
                </div>
                <div className="col-6 col-lg-6">
                  <label htmlFor="seller_field" className="form-label">
                    {" "}
                    Origin{" "}
                  </label>
                  <input
                    type="text"
                    id="origin_field"
                    className="form-control"
                    name="origin"
                    readOnly={!canUpdateProduct}
                    value={origin}
                    onChange={onChange}
                  />
                </div>
              </div>
              <div className="row my-4 ">
                <div className="col-4 col-lg-4">
                  <label htmlFor="price_field" className="form-label">
                    {" "}
                    Price{" "}
                  </label>
                  <input
                    type="number"
                    id="price_field"
                    className="form-control"
                    name="price"
                    readOnly={!canUpdateProduct}
                    value={price !== undefined ? `$${price}` : ''}
                    onChange={onChangeNumber}
                    onWheel={(e) => e.target.blur()}
                  />
                </div>
                <div className="col-4 col-lg-4">
                  <label htmlFor="pallet_size_field" className="form-label">
                    {" "}
                    Pallet Size{" "}
                  </label>
                  <input
                    type="number"
                    step="0.00001"
                    id="pallet_size_field"
                    className="form-control"
                    name="palletSize"
                    value={palletSize}
                    onChange={onChangeNumber}
                    onWheel={(e) => e.target.blur()}
                  />
                </div>
                <div className="col-4 col-lg-4">
                  <label htmlFor="weight_field" className="form-label">
                    {" "}
                    Weight
                  </label>
                  <input
                    type="text"
                    step="0.00001"
                    id="weight_field"
                    className="form-control"
                    name="weight"
                    value={weight}
                    onChange={onChangeNumber}
                    onWheel={(e) => e.target.blur()}
                  />
                </div>
              </div>
              {/* Cost */}
              <div className="my-4 col-4">
                <label htmlFor="cost_field" className="form-label">
                  Cost
                </label>
                <select className="form-control" style={{ width: '100%' }}>
                  <option 
                    value={product.cost} 
                    disabled 
                    selected>
                    {product.cost ? `$${product.cost}` : ''}
                  </option>
                  {vendorProductPriceListData?.map((item, index) => (
                    <option key={index} value={item.price} disabled>
                      {`${item.vendor.name} - $${item.price}`}
                    </option>
                  ))}
                </select>
              </div>
              {/* Description */}
              <div className="my-4">
                <label htmlFor="description_field" className="form-label">
                  Description
                </label>
                <textarea
                  className="form-control"
                  id="description_field"
                  rows="8"
                  name="description"
                  value={description}
                  onChange={onChange}
                ></textarea>
              </div>
              {/* Update button */}
              <div className="col-2 col-lg-2 offset-5">
                <button
                  type="submit"
                  className="btn w-100 py-2"
                  disabled={disableAllButtons || !isProductChanged()}
                >
                  {isUpdating ? "Updating..." : "UPDATE"}
                </button>
              </div>
              </form>
              <form className="shadow rounded bg-body mt-4" onSubmit={submitUploadImageHandler}>
                {/* Upload images*/}
                <div className="my-4 ">
                  <h3 className="mb-4">Upload Product Images</h3>
                  <div className="mb-3">
                    <label htmlFor="customFile" className="form-label">
                      Choose Images
                    </label>

                    <div className="row align-items-center">
                      {/* Select file */}
                      <div className="col-6 col-lg-6">
                        <input
                          ref={fileInputRef}
                          type="file"
                          name="product_images"
                          className="form-control"
                          id="customFile"
                          multiple
                          onChange={onChangeImageFiles}
                          onClick={handleResetFileInput}
                        />
                      </div>
                      {/* Upload button */}
                      <div className="col-2 col-lg-2 offset-1">
                        <button
                          id="register_button"
                          type="submit"
                          className="btn btn-success w-100"
                          disabled={disableAllButtons || selectedFiles.length === 0}
                        >
                          {isUploadingImages ? "Uploading..." : "Upload"}
                        </button>
                      </div>
                    </div>

                    {imagesPreview?.length > 0 && (
                      <div className="new-images my-4">
                        <p className="text-warning">New Images:</p>
                        <div className="row mt-4">
                          {imagesPreview?.map((img) => (
                            <div className="col-md-3 mt-2">
                              <div className="card">
                                <img
                                  src={img}
                                  alt="Card"
                                  className="card-img-top p-2"
                                  style={{ width: "auto", height: "80px", objectFit: "contain" }}
                                />
                                <button
                                  style={{
                                    backgroundColor: "#dc3545",
                                    borderColor: "#dc3545",
                                  }}
                                  type="button"
                                  className="btn btn-block btn-danger cross-button mt-1 py-0"
                                  onClick={() => handleImagePreviewDelete(img)}
                                >
                                  <i className="fa fa-times"></i>
                                </button>
                              </div>
                            </div>
                          ))}
                        </div>
                      </div>
                    )}
                    {/* Uploaded images */}
                    {uploadedImages?.length > 0 && (
                      <div className="uploaded-images my-4">
                        <p className="text-success">Product Uploaded Images:</p>
                        <div className="row mt-1">
                          {uploadedImages?.map((img) => (
                            <div className="col-md-2 mt-2">
                              <div className="card">
                                <img
                                  src={img?.url}
                                  alt="Card"
                                  className="card-img-top p-2"
                                  style={{ width: "auto", height: "80px", objectFit: "contain" }}

                                />
                                <button
                                  style={{
                                    backgroundColor: "#dc3545",
                                    borderColor: "#dc3545",
                                  }}
                                  className="btn btn-block btn-danger cross-button mt-1 py-0"
                                  type="button"
                                  disabled={disableAllButtons}
                                  onClick={() => deleteImage(img?.public_id)}
                                >
                                  <i className="fa fa-trash"></i>
                                </button>
                              </div>
                            </div>
                          ))}
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </form>
              {/* End of Upload images */}
            </div>
          </div>
        </AdminLayout>
        
        {/* Loader */}
        {(isUploadingImages || isDeletingImage || isUpdating) && (
          <div style={{position: 'fixed', top: '50%', left: '58%', transform: 'translate(-50%, -50%)', zIndex: 9999}}>
            <Loader />
          </div>
        )}
      </>
  );
};

export default UpdateProduct;
