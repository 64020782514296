export const getBackendURI = (url) => {
    const env = process.env.REACT_APP_NODE_ENV;
    switch (env) {
        case 'LOCAL':
            return `${process.env.REACT_APP_BACKEND_URL_TEST_LOCAL}${url}`;
        case 'DEVELOPMENT':
            return `${process.env.REACT_APP_BACKEND_URL_TEST}${url}`;
        case 'DEVELOPMENT_IN_PROGRESS':
            return `${process.env.REACT_APP_BACKEND_URL_TEST_IN_PROGRESS}${url}`;
        case 'PRODUCTION':
            return `${process.env.REACT_APP_BACKEND_URL_LIVE}${url}`;
        default:
            break;
    }
};

export const getQueryCorsMode = () => {
    return 'cors';
}