// Validate all admin form inputs
export const validateAdminProfileForm = (user) => {
    const { name, phone, email, username } = user;

    let formIsValid = true;
    const formErrors = {};

    // Validate name
    if (!name || name.trim() === "") {
        formErrors.name = "Name is required";
        formIsValid = false;
    }

    // Validate username
    if (!username || username.trim() === "") {
        formErrors.username = "Username is required";
        formIsValid = false;
    }

    // Validate phone
    if (phone.trim() === "") {
        formErrors.phone = "Phone Number is required";
        formIsValid = false;
    } else if (!/^\d{10}$/.test(phone)) {
        formErrors.phone = "Invalid phone number";
        formIsValid = false;
    }

    // Validate email
    if (!email || email.trim() === "") {
        // allow empty email for now
    } else if (!/\S+@\S+\.\S+/.test(email)) {
        formErrors.email = "Invalid email address";
        formIsValid = false;
    }

    return { formIsValid, formErrors };
};

// Validate all customer form inputs
export const validateCustomerProfileForm = (customer) => {
    const { name, email, addressInfo } = customer;
    const { address, phone, fax } = addressInfo;

    let formIsValid = true;
    const formErrors = {};

    // Validate name
    if (!name || name.trim() === "") {
        formErrors.name = "Name is required";
        formIsValid = false;
    }

    // Validate address
    if (!address || address.trim() === "") {
        formErrors.address = "Address is required";
        formIsValid = false;
    }

    // Allow empty email, but if there are emails, validate them
    if (email && email.trim() !== "") {
        // Split the input string by commas to handle multiple emails
        const emailAddresses = email.split(',');

        // Regular expression to validate email addresses
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

        // Validate each email address
        for (const emailAddress of emailAddresses) {
            const trimmedEmailAddress = emailAddress.trim();
            if (!emailRegex.test(trimmedEmailAddress)) {
                formErrors.email = "Invalid email address";
                formIsValid = false;
                break;
            }
        }
    }

    // Validate phone - allow empty phone for now
    if (phone && phone.trim() !== "") {
        // Split the input string by commas to handle multiple phone numbers
        const phoneNumbers = phone.split(',');

        // Regular expression to match phone numbers with digits, dashes, parentheses, and spaces
        const phoneRegex = /^[\d\s\-()]+$/;
        // Validate each phone number
        for (const phoneNumber of phoneNumbers) {
            const trimmedPhoneNumber = phoneNumber.trim();
            if (!phoneRegex.test(trimmedPhoneNumber)) {
                formErrors.phone = "Invalid phone number";
                formIsValid = false;
                break;
            }
        }
    }

    if (fax && fax.trim() !== "") {
            // Split the input string by commas to handle multiple fax numbers
        const faxNumbers = fax.split(',');

        // Regular expression to match fax numbers with digits, dashes, parentheses, and spaces
        const faxRegex = /^[\d\s\-()]+$/;
        // Validate each fax number
        for (const faxNumber of faxNumbers) {
            const trimmedFaxNumber = faxNumber.trim();
            if (!faxRegex.test(trimmedFaxNumber)) {
                formErrors.fax = "Invalid fax number";
                formIsValid = false;
                break;
            }
        }
    }

    return { formIsValid, formErrors };
};

export const validateUserPassword = (user) => {
    let passwordIsValid = true;
    const passwordErrors = {};
    const { password, confirmPassword, username } = user;

    // Validate password
    if (!password || password.trim() === "") {
        // Allow emtpy password for now when no username
        if (username) {
            passwordErrors.password = "Password is required when username is provided";
            passwordIsValid = false;
        }
    } else if (password.length < 8) {
        passwordErrors.password = "Password must be at least 8 characters long";
        passwordIsValid = false;
    }
    
    // Validate confirm password
    if (!confirmPassword || confirmPassword.trim() === "") {
        // Allow emtpy password for now
    } else if (password !== confirmPassword) {
        passwordErrors.confirmPassword = "Passwords do not match";
        passwordIsValid = false;
    }

    return { passwordIsValid, passwordErrors };
}

// Validate all vendor form inputs
export const validateVendorProfileForm = (vendor) => {
    const { name, email, addressInfo } = vendor;
    const { address, phone, fax } = addressInfo;

    let formIsValid = true;
    const formErrors = {};

    // Validate name
    if (!name || name.trim() === "") {
        formErrors.name = "Name is required";
        formIsValid = false;
    }

    // Validate address
    if (!address || address.trim() === "") {
        formErrors.address = "Address is required";
        formIsValid = false;
    }

    // Allow empty email, but if there are emails, validate them
    if (email && email.trim() !== "") {
        // Split the input string by commas to handle multiple emails
        const emailAddresses = email.split(',');

        // Regular expression to validate email addresses
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

        // Validate each email address
        for (const emailAddress of emailAddresses) {
            const trimmedEmailAddress = emailAddress.trim();
            if (!emailRegex.test(trimmedEmailAddress)) {
                formErrors.email = "Invalid email address";
                formIsValid = false;
                break;
            }
        }
    }

    // Validate phone
    if (!phone || phone.trim() === "") {
        formErrors.phone = "Phone Number is required";
        formIsValid = false;
    } else {
            // Split the input string by commas to handle multiple phone numbers
        const phoneNumbers = phone.split(',');

        // Regular expression to match phone numbers with digits, dashes, parentheses, and spaces
        const phoneRegex = /^[\d\s\-()]+$/;
        // Validate each phone number
        for (const phoneNumber of phoneNumbers) {
            const trimmedPhoneNumber = phoneNumber.trim();
            if (!phoneRegex.test(trimmedPhoneNumber)) {
                formErrors.phone = "Invalid phone number";
                formIsValid = false;
                break;
            }
        }
    }

    if (!fax || fax.trim() === "") {
        // Allow empty fax for now
    } else {
            // Split the input string by commas to handle multiple fax numbers
        const faxNumbers = fax.split(',');

        // Regular expression to match fax numbers with digits, dashes, parentheses, and spaces
        const faxRegex = /^[\d\s\-()]+$/;
        // Validate each fax number
        for (const faxNumber of faxNumbers) {
            const trimmedFaxNumber = faxNumber.trim();
            if (!faxRegex.test(trimmedFaxNumber)) {
                formErrors.fax = "Invalid fax number";
                formIsValid = false;
                break;
            }
        }
    }
    
    return { formIsValid, formErrors };
};

export const valueIsANumber = (value) => {
    return value && !isNaN(value) && value !== '' && !String(value).includes('e');
};

export const valueIsANonNegativeNumber = (value) => {
    return value && !isNaN(value) && value !== '' && !String(value).includes('e') && value >= 0;
};