import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { toast } from "react-hot-toast";
import Loader from "../../layout/Loader.jsx";
import HeaderAdmin from "../../layout/HeaderAdmin";
import MetaData from "../../layout/MetaData";

import { useAdminOrderDetailsQuery } from "../../../redux/api/orderAdminApi";
import PrintPackingSlip from "../../printings/PackingSlip.jsx";

const AdminPackingSlip = () => {
  const params = useParams();
  const orderId = params?.id;

  const [order, setOrder] = useState(null); // Order details

  const { isLoading: loadingOrderDetails, data: orderDetails, error: loadingOrderDetailError } = useAdminOrderDetailsQuery({ id: orderId, extraParams: 'fullProductDetail,confirmedBy'}, { refetchOnMountOrArgChange: true });
  const { loadingAdmin, user } = useSelector((state) => state.adminAuth);

  useEffect(() => {
    if (user && orderDetails?.order) {
        const order = orderDetails.order;
        setOrder(order);
    }
  }, [user, orderDetails]);

  useEffect(() => {
      if (loadingOrderDetailError) {
        toast.error(loadingOrderDetailError?.data?.message);
      } 
  }, [loadingOrderDetailError]);

  return (
    <>
      <MetaData title={"Order Packing Slip"} />
      <HeaderAdmin />

      {(loadingAdmin || loadingOrderDetails) && (<Loader/>)}
      {order && (
        <div style={{width: '95vw', display: 'flex', justifyContent: 'center'}}>
          <PrintPackingSlip order={order} />
        </div>
      )}
    </>     
  );
};

export default AdminPackingSlip;