import React, { useEffect, useState } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import Loader from "../../layout/Loader";
import MetaData from "../../layout/MetaData";
import HeaderAdmin from "../../layout/HeaderAdmin";
import AdminLayout from "../../layout/AdminLayout";
import { toast } from "react-hot-toast";
import { MDBDataTable } from "mdbreact";
import { useSelector } from "react-redux";

import { useAdminPayDeliveryMutation, useAdminGetDriverDetailsQuery, useLazyAdminGetDriverDeliveriesQuery, useAdminUpdateDriverDeliveryMutation } from "../../../redux/api/adminApi";
import { useLazyAdminLogoutQuery } from "../../../redux/api/adminAuthApi";
import { ConfirmDialog } from "../ConfirmDialog";
import { CanUpdateDriver } from "../AdminActionEligibilities";
import { CenterAlignedCell, EditableNumberCell, RightAlignedCell } from "../../layout/CustomMDBDataTableCell";

import { ADMIN_MENU_ITEMS, DRIVER_DELIVERY_STATUS, USER_ROLES } from "../../../constants/constants.js";
import Pagination from "react-js-pagination";

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const ListDriverDeliveries = () => {
  const navigate = useNavigate();

  // Get URL params
  const driverId = useParams().id;

  // Get search params
  const [ page, setPage ] = useState(1);
  const firstDayOfMonth = new Date();
  firstDayOfMonth.setDate(1);
  const [startDate, setStartDate] = useState(firstDayOfMonth);
  const [endDate, setEndDate] = useState(new Date());
  const [filter, setFilter ] = useState(DRIVER_DELIVERY_STATUS.CURRENT);

  const { loadingAdmin, user } = useSelector((state) => state.adminAuth);
  const [adminLogout] = useLazyAdminLogoutQuery(undefined, { refetchOnMountOrArgChange: true });
  const { data: driverData, isLoading: isLoadingDriverDetails, error: errorLoadingDriverDetails } = useAdminGetDriverDetailsQuery(driverId);
  const [AdminGetDriverDeliveries, { isLoading: isLoadingDriverDeliveries, error: errorLoadingDriverDeliveries }] = useLazyAdminGetDriverDeliveriesQuery();
  const [adminUpdateDriverDelivery, { data: updatedDriverDeliveryData, isLoading: isUpdatingDriverDelivery, error: errorUpdatingDriverDelivery }] = useAdminUpdateDriverDeliveryMutation();
  const [adminPayDelivery, { data: payDeliveryData, isLoading: isPayingDeliveries, error: errorPayingDeliveries }] = useAdminPayDeliveryMutation();

  const [driverDeliveriesData, setDriverDeliveriesData] = useState(null);
  const [deliveries, setDeliveries] = useState([]);
  const [clonedDeliveries, setClonedDeliveries] = useState([]);
  const [deliveryToUpdate, setDeliveryToUpdate] = useState(null);
  const [showConfirmPaymentPopup, setShowConfirmPaymentPopup] = useState(false);
  const [selectedDeliveries, setSelectedDeliveries] = useState([]);
  const [totalAmount, setTotalAmount] = useState(0);
  const [paymentIdForSelectedDeliveries, setPaymentIdForSelectedDeliveries] = useState("");

  useEffect(() => {
    if (errorLoadingDriverDeliveries) {
      toast.error(errorLoadingDriverDeliveries?.message);
      if (errorLoadingDriverDeliveries?.status === 401) {
        adminLogout();
      }
    }

    if (errorUpdatingDriverDelivery) {
      toast.error(errorUpdatingDriverDelivery?.data?.message);
    }

    if (errorPayingDeliveries) {
      toast.error(errorPayingDeliveries?.data?.message);
    }
  }, [errorLoadingDriverDeliveries, errorUpdatingDriverDelivery, errorPayingDeliveries]);

  useEffect(() => {
    if (errorLoadingDriverDetails) {
      toast.error(errorLoadingDriverDetails?.data?.message);
      if (errorLoadingDriverDetails.status === 401) {
        adminLogout();
      }
    }
    else if (errorLoadingDriverDeliveries) {
      toast.error(errorLoadingDriverDeliveries?.data?.message);
      if (errorLoadingDriverDeliveries.status === 401) {
        adminLogout();
      }
    }
  }, [errorLoadingDriverDetails, errorLoadingDriverDeliveries]);

  useEffect(() => {
    // History deliveries needs to be fetched by button click
    if (filter === DRIVER_DELIVERY_STATUS.CURRENT) {
      setPage(1);
      fetchDriverDeliveries(filter, 1);
    }
  }, [filter]);

  useEffect(() => {
    if (updatedDriverDeliveryData?.delivery) {
      setDeliveries((prevDeliveries) =>
        prevDeliveries.map((delivery) =>
          delivery._id === updatedDriverDeliveryData.delivery._id ? updatedDriverDeliveryData.delivery : delivery
        )
      );
      const updatedDeliveries = clonedDeliveries.map((delivery) =>
        delivery._id === updatedDriverDeliveryData.delivery._id ? updatedDriverDeliveryData.delivery : delivery
      );
      setClonedDeliveries(JSON.parse(JSON.stringify(updatedDeliveries)));
    }
  }, [updatedDriverDeliveryData]);

  useEffect(() => {
    if (payDeliveryData && payDeliveryData.success) {
      setSelectedDeliveries([]);
      setTotalAmount(0);
      setPaymentIdForSelectedDeliveries("");
      toast.success("Payment successful");

      // Refetch the data
      fetchDriverDeliveries(filter, page);
    }
  }, [payDeliveryData]);

  function confirmUpdatingDelivery(deliveryId) {
    const index = clonedDeliveries.findIndex((delivery) => delivery._id === deliveryId);
    adminUpdateDriverDelivery({deliveryId: clonedDeliveries[index]._id, body: {isHistory: filter === DRIVER_DELIVERY_STATUS.HISTORY, paymentId: clonedDeliveries[index].paymentId, amount: clonedDeliveries[index].amount}});
    setDeliveryToUpdate(null);
  }

  function fetchDriverDeliveries(selectedFilter, searchPage) {
    const timestamp = new Date().getTime();
    // Call the API
    AdminGetDriverDeliveries({driverId, filter: selectedFilter, params: { page: searchPage, startDate, endDate, _: timestamp  }})
      .then((response) => {
        // Update state with the fetched data
        if (response.data) {
          setDriverDeliveriesData(response.data);
          setDeliveries(response.data.deliveries);
          setClonedDeliveries(JSON.parse(JSON.stringify(response.data.deliveries)));
        }
      })
      .catch((error) => {
        console.error('Error fetching deliveries:', error);
      });
  }

  function handleFilterChange(selectedFilter) {  
    if (filter !== selectedFilter) {
      setFilter(selectedFilter);
      // Reset page when filter changes
      setDeliveries([]);
      setClonedDeliveries([]);
      setDeliveriesData(null);
      setPage(1);
    }
  }

  function setCurrentPageNo(pageNumber) {
    setPage(pageNumber);
    fetchDriverDeliveries(filter, pageNumber);
  }

  function handlePaymentIdChange(index, value) {
    // trim leading and trailing spaces
    value = value.trim();
    const newDeliveries = [...clonedDeliveries];
    newDeliveries[index].paymentId = value;
    setClonedDeliveries(newDeliveries);
  }

  function handlePaymentAmountChange(index, value) {
    // trim leading and trailing spaces
    value = value.trim();
    const newDeliveries = [...clonedDeliveries];
    newDeliveries[index].amount = value;
    setClonedDeliveries(newDeliveries);
  }

  const handleOrderRowClick = (orderId) => {
    if (filter === DRIVER_DELIVERY_STATUS.CURRENT) {
      navigate(`/admin/orders/${orderId}`);
    } else {
      navigate(`/admin/oldorders/${orderId}`);
    }
  };

  const handleCheckboxChange = (deliveryId) => {
    setSelectedDeliveries((prevSelected) => {
      let newSelected;
      if (prevSelected.includes(deliveryId)) {
        newSelected = prevSelected.filter((id) => id !== deliveryId);
      } else {
        newSelected = [...prevSelected, deliveryId];
      }
      const newTotal = newSelected.reduce((sum, id) => {
        const delivery = deliveries.find(delivery => delivery._id === id);
        return sum + (delivery?.amount ? delivery.amount : 0);
      }, 0);
      setTotalAmount(newTotal);
      return newSelected;
    });
  };

  // -------------------- Handle payment ------------------------
  const handlePaymentClick = () => {
    setShowConfirmPaymentPopup(true);
  }

  const handleConfirmPayment= () => {
    adminPayDelivery({ body: { deliveries: selectedDeliveries, paymentId: paymentIdForSelectedDeliveries, isHistory: filter === DRIVER_DELIVERY_STATUS.HISTORY } });
    setShowConfirmPaymentPopup(false);
  };
  // ---------------------------------------------------

  const canUpdate = CanUpdateDriver(user);

  const setDeliveriesData = () => {
    const columns = [
      {
        label: <CenterAlignedCell value={"ID"}> </CenterAlignedCell>,
        field: "id",
      },
      {
        label: <CenterAlignedCell value={"Order #"}> </CenterAlignedCell>,
        field: "orderNumber",
      },
      {
        label: <CenterAlignedCell value={"Customer"}> </CenterAlignedCell>,
        field: "customerName",
      },
      {
        label: <CenterAlignedCell value={"Pallet"}> </CenterAlignedCell>,
        field: "pallet",
      },
      {
        label: <CenterAlignedCell value={"Date"}> </CenterAlignedCell>,
        field: "date",
      },
      {
        label: <CenterAlignedCell value={"Payment Amount"}> </CenterAlignedCell>,
        field: "amount",
      },   
      {
        label: <CenterAlignedCell value={"Payment #"}> </CenterAlignedCell>,
        field: "paymentId",
      },        
      {
        label: <CenterAlignedCell value={"Action"}> </CenterAlignedCell>,
        field: "action",
      },      
    ];
    if (filter === DRIVER_DELIVERY_STATUS.CURRENT) {
      columns.push({
        label: <CenterAlignedCell value={"Pay Select"}> </CenterAlignedCell>,
        field: "selected",
      });
    }

    const deliveriesTable = {
      columns,
      rows: [],
    };

    const startIndex = driverDeliveriesData?.resPerPage && page ? (page - 1) * driverDeliveriesData?.resPerPage : 0;

    clonedDeliveries?.forEach((delivery, index) => {
      const row = {
        id: <RightAlignedCell value={index + startIndex + 1} />,
        orderNumber: <RightAlignedCell value={delivery.orderNumber} onClick={() => { handleOrderRowClick(delivery.order)}} />,
        customerName: delivery.customer,
        pallet: <CenterAlignedCell value={delivery.pallet} />,
        date: <CenterAlignedCell value={new Date(delivery.date).toLocaleString()} />,
        paymentId: <EditableNumberCell value={delivery.paymentId} color={clonedDeliveries[index].paymentId === deliveries[index].paymentId ? 'black' : 'red'} type={'text'} onClick={(event) => event.stopPropagation()} onChange={(val) => {handlePaymentIdChange(index, val)}}/>,
        amount: <EditableNumberCell value={delivery.amount} color={clonedDeliveries[index].amount === deliveries[index].amount ? 'black' : 'red'} type={'text'} onClick={(event) => event.stopPropagation()} onChange={(val) => {handlePaymentAmountChange(index, val)}}/>,
        selected: <CenterAlignedCell 
                    value={
                      <input type="checkbox" 
                        checked={selectedDeliveries.includes(delivery._id)} 
                        onClick={(event) => event.stopPropagation()} 
                        onChange={() => handleCheckboxChange(delivery._id)}
                        style={{
                          height: '1.5em', // Adjust this value as needed
                          width: '1.5em',  // Maintain aspect ratio
                        }}
                      />
                    } 
                  />,
        action: canUpdate && (
          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <button
              className="btn btn-primary"
              disabled={clonedDeliveries[index].amount === deliveries[index].amount && clonedDeliveries[index].paymentId === deliveries[index].paymentId}
              onClick={() => {
                setDeliveryToUpdate(delivery._id);
              }}
            >
              Update
            </button>
          </div>)
      };
      deliveriesTable.rows.push(row);
    });
    return deliveriesTable;
  };

  if (isLoadingDriverDetails || loadingAdmin) return <Loader />;

  let deliveryToUpdateString = "";
  let selectedOrderNumbers = "";
  if (deliveries) {
    deliveryToUpdateString = deliveryToUpdate ? `#${deliveries.find((delivery) => delivery._id === deliveryToUpdate).orderNumber}` : "";
  }  
  if (selectedDeliveries && selectedDeliveries.length > 0) {
    selectedOrderNumbers = selectedDeliveries.map(id => {
      const delivery = deliveries.find(delivery => delivery._id === id);
      return delivery ? delivery.orderNumber : '';
    }).join(', ');
  }

  return (
    <>
      <MetaData title={"Driver Deliveries"} />
      <HeaderAdmin title={"Driver Deliveries"} />
      <AdminLayout menuItem={ADMIN_MENU_ITEMS.DRIVERS.name}>
      <div className="col-12 align-items-center">
        <div className="row my-4">
          <div className="col-4">
            <h1>{driverData.driver.name}</h1>
          </div>
        </div>  
        <div className="row col-11">
          <div className="col-2">
          <label className="form-label d-block">&nbsp;</label>
            <select
              id="type_field"
              className="form-select"
              name="filter"
              value={filter}
              onChange={(e) => handleFilterChange(e.target.value)}
            >
              <option value={DRIVER_DELIVERY_STATUS.CURRENT}>CURRENT</option>
              <option value={DRIVER_DELIVERY_STATUS.HISTORY}>HISTORY</option>
            </select>
          </div>
          { filter === DRIVER_DELIVERY_STATUS.HISTORY && (
            <div className="col-2 offset-1 justify-content-center">
                <label className="form-label d-block">Start Date</label>
                <DatePicker
                  style={{ width: "100%" }}
                  selected={startDate}
                  onChange={(date) => setStartDate(date)}
                  selectsStart
                  startDate={startDate}
                  endDate={endDate}
                  className="form-control"
                />
            </div>
          )}
          { filter === DRIVER_DELIVERY_STATUS.HISTORY && (
            <div className="col-2 justify-content-center">
              <label className="form-label d-block">End Date</label>
              <DatePicker
                style={{ width: "100%" }}
                selected={endDate}
                onChange={(date) => setEndDate(date)}
                selectsEnd
                startDate={startDate}
                endDate={endDate}
                minDate={startDate}
                className="form-control"
              />
            </div>
          )}
          { filter === DRIVER_DELIVERY_STATUS.HISTORY && (
            <div className="col-2 justify-content-center">
              <label className="form-label d-block">&nbsp;</label>
                <button
                  className="btn w-100 fetch-btn"
                  onClick={() => setCurrentPageNo(1)}
                >
                  Fetch
                </button>
            </div>
          )}

          { filter === DRIVER_DELIVERY_STATUS.CURRENT && selectedDeliveries.length > 0 && (
            <div className="col-8 offset-2 justify-content-center">
              <div className="row">
                <div className="col-4  text-end">
                  Total:
                </div>
                <div className="col-4  text-center">
                  <h3>{`$${totalAmount} `}</h3>
                </div>
              </div>
              <div className="row">
                <div className="col-4  text-end">
                  Payment ID:
                </div>
                <div className="col-4">
                  <input
                    type="text"
                    className="form-control"
                    value={paymentIdForSelectedDeliveries}
                    onChange={(e) => setPaymentIdForSelectedDeliveries(e.target.value)}
                  />
                </div>
                <div className="col-4">
                  <button
                    style={{ width: "100%" }}
                    className="btn btn-primary"
                    onClick={() => { handlePaymentClick() }}
                  >
                    Pay
                  </button>
                </div>
              </div>
            </div>
          )}          
        </div>
        
        <div className="col-11">
          {/* Loader */}
          {(isLoadingDriverDeliveries || isUpdatingDriverDelivery || isPayingDeliveries) && (
            <div style={{position: 'relative', height: '100%', width: '100%'}}>
              <div style={{position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', zIndex: 6}}>
                <Loader />
              </div>
            </div>
          )}
          <MDBDataTable 
            data={setDeliveriesData()} 
            className="px-3 myMDBDataListDriverDeliveriesTableRow" 
            bordered striped hover 
            noBottomColumns
            paging={false}
          />
        </div>
        {/* Pagination - only on old deliveries page*/}
        {driverDeliveriesData && driverDeliveriesData.resPerPage < driverDeliveriesData.totalDeliveryCount && (
          <div className="d-flex col-11 justify-content-center">
            <Pagination
              activePage={page}
              itemsCountPerPage={Number(driverDeliveriesData.resPerPage)}
              totalItemsCount={Number(driverDeliveriesData.totalDeliveryCount)}
              onChange={setCurrentPageNo}
              nextPageText={"Next"}
              prevPageText={"Prev"}
              firstPageText={"First"}
              lastPageText={"Last"}
              itemClass="page-item"
              linkClass="page-link"
            />
          </div>
        )}
      
      <ConfirmDialog message={`Are you sure to update delivery for order ${deliveryToUpdateString}?`} show={deliveryToUpdate !== null} confirm={()=>{confirmUpdatingDelivery(deliveryToUpdate)}} cancel={()=> {setDeliveryToUpdate(null)}}> </ConfirmDialog>
      <ConfirmDialog
          message={`Are you sure you want to process payment for the selected deliveries with order numbers: ${selectedOrderNumbers}?`}
          show={showConfirmPaymentPopup}
          confirm={() => handleConfirmPayment()}
          cancel={() => setShowConfirmPaymentPopup(false)}
      />
      </div>
      </AdminLayout>
    </>
  );
};

export default ListDriverDeliveries;
