import React from "react";
import { Route } from "react-router-dom";

import { ProtectedCustomerRoute } from "../auth/ProtectedCustomerRoute";

import ProductDetails from "../product/ProductDetails";
import CustomerLogin from "../auth/CustomerLogin";
//import Register from "../auth/Register";
import CustomerProfile from "../customer/CustomerProfile";
import UpdateCustomerProfile from "../customer/UpdateCustomerProfile";
import UploadCustomerAvatar from "../customer/UploadCustomerAvatar";
import UpdateCustomerPassword from "../customer/UpdateCustomerPassword";
import ForgotPassword from "../auth/CustomerForgotPassword";
import ResetPassword from "../auth/CustomerResetPassword";
import Cart from "../cart/Cart";
import Shipping from "../cart/Shipping";
import ConfirmOrder from "../cart/ConfirmOrder";
import MyOrders from "../order/MyOrders";
import OrderDetails from "../order/OrderDetails";
import CloneOrder from "../order/CloneOrder";
import MyOldOrders from "../order/MyOldOrders";
import OldOrderDetails from "../order/OldOrderDetails";
import Home from "../HomePage/Home";
import Products from "../HomePage/Products";
import Contact from "../HomePage/Contact";
import Shop from "../HomePage/Shop";
import { CustomerInvoice, OldCustomerInvoice } from "../customer/CustomerInvoice";
import PrivacyPolicy from "../HomePage/Privacy";

const userRoutes = () => {
  return (
    <>
      <Route path="/" element={<Home />} />
      <Route path="/login" element={<CustomerLogin />} />
      <Route path="/categories" element={<Products />} />
      <Route path="/products/:id" element={<ProtectedCustomerRoute><ProductDetails /> </ProtectedCustomerRoute>} />
      <Route path="/shop" element={<Shop />} />
      <Route path="/contact" element={<Contact />} />
      <Route path="/privacy" element={<PrivacyPolicy />} />
      {/*<Route path="/register" element={<Register />} />*/}

      <Route path="/password/forgot" element={<ForgotPassword />} />
      <Route path="/password/reset/:token" element={<ResetPassword />} />

      <Route path="/me/profile" element={<ProtectedCustomerRoute><CustomerProfile /></ProtectedCustomerRoute>}/>
      <Route path="/me/update_profile"element={<ProtectedCustomerRoute><UpdateCustomerProfile /></ProtectedCustomerRoute>}/>
      <Route path="/me/upload_avatar" element={<ProtectedCustomerRoute><UploadCustomerAvatar /></ProtectedCustomerRoute>}/>
      <Route path="/me/update_password" element={<ProtectedCustomerRoute><UpdateCustomerPassword /></ProtectedCustomerRoute>}/>
      <Route path="/me/orders" element={<ProtectedCustomerRoute><MyOrders /></ProtectedCustomerRoute>}/>
      <Route path="/me/orders/:id" element={<ProtectedCustomerRoute><OrderDetails /></ProtectedCustomerRoute>}/>
      <Route path="/me/orders/:id/clone" element={<ProtectedCustomerRoute><CloneOrder /></ProtectedCustomerRoute>}/>
      <Route path="/me/oldorders" element={<ProtectedCustomerRoute><MyOldOrders /></ProtectedCustomerRoute>}/>
      <Route path="/me/oldorders/:id" element={<ProtectedCustomerRoute><OldOrderDetails /></ProtectedCustomerRoute>}/>

      <Route path="/cart" element={<ProtectedCustomerRoute><Cart /></ProtectedCustomerRoute>} />
      <Route path="/shipping" element={<ProtectedCustomerRoute><Shipping /></ProtectedCustomerRoute>}/>
      <Route path="/confirm_order" element={<ProtectedCustomerRoute><ConfirmOrder /></ProtectedCustomerRoute>}/>
      <Route path="/invoice/order/:id" element={<ProtectedCustomerRoute><CustomerInvoice /></ProtectedCustomerRoute>}/>
      <Route path="/invoice/oldorder/:id" element={<ProtectedCustomerRoute><OldCustomerInvoice/></ProtectedCustomerRoute>}/>
    </>
  );
};

export default userRoutes;
