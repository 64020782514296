import React, { useEffect, useState } from "react";
import { useCustomerForgotPasswordMutation } from "../../redux/api/customerApi";
import { useSelector } from "react-redux";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import MetaData from "../layout/MetaData";
import Header from "../layout/Header";

const CustomerForgotPassword = () => {
  const [email, setEmail] = useState("");
  const [emailSent, setEmailSent] = useState(false);  

  const navigate = useNavigate();

  const [forgotPassword, { isLoading, error, isSuccess }] = useCustomerForgotPasswordMutation();

  const { isAuthenticated } = useSelector((state) => state.customerAuth);

  useEffect(() => {
    if (isAuthenticated) {
      navigate("/shop");
    }
    if (error) {
      toast.error(error?.data?.message);
    }

    if (isSuccess) {
      setEmailSent(true);
      toast.success("Email Sent. Please check your inbox");
    }
  }, [error, isAuthenticated, isSuccess]);

  const submitHandler = (e) => {
    e.preventDefault();

    forgotPassword({ email });
  };

  return (
    <>
      <MetaData title={"Forgot Password"} />
      <Header noVerification={true} />
      <div className="container">
        <div className="row wrapper">
          <div className="col-10 col-lg-5">
            <form className="shadow rounded bg-body" onSubmit={submitHandler}>
              <h2 className="mb-4">Forgot Password</h2>
              <div className="mt-3">
                <label htmlFor="email_field" className="form-label">
                  Enter Email
                </label>
                <input
                  type="email"
                  id="email_field"
                  className="form-control"
                  name="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  autoComplete="tom-produce-email"
                />
              </div>

              <button
                id="forgot_password_button"
                type="submit"
                className="btn w-100 py-2"
                disabled={isLoading || emailSent}
              >
                {emailSent ? "Email Sent" : (isLoading ? "Sending..." : "Send Email")}
              </button>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default CustomerForgotPassword;
