import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import { useSelector } from "react-redux";

import AdminProfileLayout from "../../layout/AdminProfileLayout";
import { useAdminUploadAvatarMutation } from "../../../redux/api/adminApi";
import MetaData from "../../layout/MetaData";
import HeaderAdmin from "../../layout/HeaderAdmin";
import { useLazyAdminLogoutQuery } from "../../../redux/api/adminAuthApi";

const UploadAdminAvatar = () => {
  const { user } = useSelector((state) => state.adminAuth);
  const [avatar, setAvatar] = useState();
  const [avatarPreview, setAvatarPreview] = useState(
    user?.avatar ? user?.avatar?.url : "/images/default_avatar.jpg"
  );

  const navigate = useNavigate();
  const [adminLogout] = useLazyAdminLogoutQuery();
  const [adminUploadAvatar, { isLoading, error, isSuccess }] = useAdminUploadAvatarMutation();

  useEffect(() => {
    if (isSuccess) {
      toast.success("Avatar Uploaded");
      navigate("/admin/me/profile");
    }
  }, [error]);

  useEffect(() => {
    if (error) {
      toast.error(error?.data?.message);
      if (error.status === 401) {
        adminLogout();
      }
    }
  }, [error]);

  const submitHandler = (e) => {
    e.preventDefault();
    adminUploadAvatar({avatar});
  };

  const onChange = (e) => {
    const reader = new FileReader();
    reader.onload = () => {
      if (reader.readyState === 2) {
        const result = reader.result;
        setAvatarPreview(result);
        setAvatar(result);
      }
    };
    reader.readAsDataURL(e.target.files[0]);
  };

  return (
    <>
      <MetaData title={"Upload Avatar"} />
      <HeaderAdmin />
      <div className="container">
        <AdminProfileLayout title="Upload Avatar">
          <div className="row wrapper">
            <div className="col-10 col-lg-8">
              <form className="shadow rounded bg-body" onSubmit={submitHandler}>
                <h2 className="mb-4">Upload Avatar</h2>

                <div className="mb-3">
                  <div className="d-flex align-items-center">
                    <div className="me-3">
                      <figure className="avatar item-rtl">
                        <img
                          src={avatarPreview}
                          className="rounded-circle"
                          alt="Avatar Preview"
                        />
                      </figure>
                    </div>
                    <div className="input-form">
                      <label className="form-label" htmlFor="customFile">
                        Choose Avatar
                      </label>
                      <input
                        type="file"
                        name="avatar"
                        className="form-control"
                        id="customFile"
                        onChange={onChange}
                      />
                    </div>
                  </div>
                </div>

                <button
                  id="register_button"
                  type="submit"
                  className="btn btn-success w-50 py-2"
                  disabled={isLoading}
                >
                  {isLoading ? "Uploading..." : "Upload"}
                </button>
              </form>
            </div>
          </div>
        </AdminProfileLayout>
      </div>
    </>
  );
};

export default UploadAdminAvatar;
