import React from 'react';
import Select from 'react-select';
import { PRODUCT_CATEGORIES } from '../../constants/constants.js';

export const CategorySelection = ({ onChange, value }) => {
    const formattedProductCategories = PRODUCT_CATEGORIES.map(category => ({
        value: category.id,
        label: `${category.name} - ${category.otherName}`
      }));

    return (
        <div>
            <Select 
                value={formattedProductCategories.find(category => category.value === value)}
                onChange={(selectedOption) => {onChange(selectedOption.value);}}
                options={formattedProductCategories} 
            />    
        </div>
    );
};