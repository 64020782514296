import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';   // Import createApi and fetchBaseQuery from the toolkit/query/react module
import { getBackendURI, getQueryCorsMode } from './helper.js';

export const priceListAdminApi = createApi({   // Create a new API using the createApi function
    reducerPath: 'priceListAdminApi',   // Define a unique reducer path for the API
    baseQuery: fetchBaseQuery(
      { 
        baseUrl: getBackendURI('/api/v1/admin'),
        mode: getQueryCorsMode(),
        credentials: 'include', // Include cookies in the request
      }),   // Define the base query using the fetchBaseQuery function
    tagTypes: ["PriceList"],   // Define the tag types for the API
    endpoints: (builder) => ({   // Define the endpoints for the API
        // Get price list
        adminGetPriceList: builder.query({
            query: (vendorId) => ({
              url: `/vendors/${vendorId}/pricelist`,
            }),
            transformResponse: (result) => result.prices,
            providesTags: ["PriceList"],
        }),
        adminUpdatePriceList: builder.mutation({
          query({ vendorId, body }) {
            return {
              url: `/vendors/${vendorId}/updatepricelist`,
              method: "PUT",
              body,
            };
          },
          invalidatesTags: ["PriceList"],
        }),
        adminRemoveAPriceListItem: builder.mutation({
          query({ vendorId, productId }) {
            return {
              url: `/vendors/${vendorId}/pricelist/remove/${productId}`,
              method: "PUT",
            };
          },
          invalidatesTags: ["PriceList"],
        }),
        // Get price list by product
        adminGetVendorProductPriceList: builder.query({
          query: (productId) => ({
            url: `/products/${productId}/vendorpricelist`,
          }),
          transformResponse: (result) => result.prices,
          providesTags: ["PriceList"],
        }),
    }),
});

export const { 
    useAdminGetPriceListQuery, useLazyAdminGetPriceListQuery, useAdminUpdatePriceListMutation, useAdminRemoveAPriceListItemMutation,
    useLazyAdminGetVendorProductPriceListQuery,
    useAdminGetVendorProductPriceListQuery
} = priceListAdminApi;