import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import socketIOClient from "socket.io-client";

import { productsApi } from "../../redux/api/productsApi";
import { customerApi } from "../../redux/api/customerApi";

export const DatabaseTrackingStreamCustomer = () => {
  const { isAuthenticated } = useSelector((state) => state.customerAuth);
    const dispatch = useDispatch();

    const allCollections = ['Product', 'Customer'];
    let socket;

    const connectSocket = () => {
      let URI;
      if (process.env.REACT_APP_NODE_ENV === 'LOCAL') {
        URI = `${process.env.REACT_APP_BACKEND_SOCKET_ENDPOINT_TEST_LOCAL}`;
      } else if (process.env.REACT_APP_NODE_ENV === 'DEVELOPMENT') {
        URI = `${process.env.REACT_APP_BACKEND_SOCKET_ENDPOINT_TEST}`;
      } else if (process.env.REACT_APP_NODE_ENV === 'DEVELOPMENT_IN_PROGRESS') {
        URI = `${process.env.REACT_APP_BACKEND_SOCKET_ENDPOINT_TEST_IN_PROGRESS}`;
      } else {
        URI = `${process.env.REACT_APP_BACKEND_SOCKET_ENDPOINT_LIVE}`;
      } 
      socket = socketIOClient(URI, {
        reconnection: true,
        reconnectionAttempts: Infinity,
        reconnectionDelay: 1000,
        reconnectionDelayMax: 5000,
        randomizationFactor: 0.5,
        withCredentials: true,
        transports: ["websocket"],
      });

      allCollections.forEach(collection => {
        socket.off(`${collection}`);
      });

      allCollections.forEach(collection => {
        socket.on(`${collection}`, data => {
          if (collection === 'Product') {
            dispatch(productsApi.util.invalidateTags(['Product']));
          } else {
            dispatch(customerApi.util.invalidateTags(['Customer']));
          }
        });
      });
    };

    const disconnectSocket = () => {
      if (socket) {
        allCollections.forEach(collection => {
          socket.off(`${collection}`);
        });
        socket.disconnect();
      }
    };    

    useEffect(() => {
      if (isAuthenticated) {
        // Connect the socket when the component mounts
        connectSocket();
      
        // Disconnect the socket when the component unmounts
        return disconnectSocket;
      }
    }, [isAuthenticated]);

    // Automatically disconnect and reconnect the socket when the page loses and regains focus
    useEffect(() => {
      if (isAuthenticated) {
        const handleVisibilityChange = () => {
          if (document.hidden) {
            disconnectSocket();
          } else {
            connectSocket();
          }
        };
        window.addEventListener('visibilitychange', handleVisibilityChange);

        // Remove the event listener when the component unmounts
        return () => {
          window.removeEventListener('visibilitychange', handleVisibilityChange);
        };
      }
    }, [isAuthenticated]);
    return (<></>);
};
