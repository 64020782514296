import React, { useState, useEffect } from "react";
import { valueIsANonNegativeNumber } from "../../utils/validators";
import _ from "lodash";

export const EditableNumberCell = (data) => {
  const [validValue, setValidValue] = useState();
  const [value, setValue] = useState();
  const [formUpdated, setFormUpdated] = useState(false);
  const type = data?.type ? data.type : "number";
  const backgroundColor = data?.backgroundColor;  

  useEffect(() => {
    setValue(data?.value);
    setValidValue(data?.value);
  }, [data?.value]);

  const handleChange = (event) => {
    const newValue = event.target.value;
    setValue(newValue);
    if ((type !== 'number' || valueIsANonNegativeNumber(newValue)) && data?.value !== newValue) {
      setValidValue(newValue);
      if (data?.onChange) {
        data.onChange(newValue);
      }
      setFormUpdated(!formUpdated);
    }
  };

  const handleBlur = () => {
    setFormUpdated(!formUpdated);
    setValue(validValue);
    if (data?.onBlur) {
      data.onBlur(validValue);
    }
  }

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      handleBlur();
    }
    if (event.key === 'ArrowUp' || event.key === 'ArrowDown') {
      event.preventDefault();
    }
  }

  return (
    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
      <input
        className="no-arrows"
        id={data?.id}
        formUpdated={formUpdated}
        type={type}
        value={value}
        onBlur={handleBlur}
        onChange={handleChange}
        onKeyDown={handleKeyDown}
        disabled={data?.disabled}
        readOnly={data?.readOnly}
        onWheel={(e) => e.target.blur()}
        style={{ textAlign: "right", color: data.color, width: "100%", border: "none", backgroundColor: backgroundColor ? backgroundColor : "transparent" }}
      />
    </div>  
  );
};

export const RightAlignedCell = ({ value, onClick, backgroundColor, color }) => {
  return  <div style={{ textAlign: "right", backgroundColor: backgroundColor, color: color }} onClick={onClick ? onClick : undefined}>
            {value}
          </div>;
}

export const CenterAlignedCell = ({ value, onClick }) => {
  return  <div style={{ textAlign: "center" }} onClick={onClick ? onClick : undefined}>
            {value}
          </div>;
}

export const LeftAlignedCell = ({ value, onClick }) => {
  return  <div style={{ textAlign: "left" }} onClick={onClick ? onClick : undefined}>
            {value}
          </div>;
}

export const ButtonCell = ({ buttonType, labelType, disabled, action, label, width }) => {
  const handleClick = () => {
    action();
  };
  const style = width ? {width} : {};

  return (
    <button style={style} className={buttonType} onClick={() => {handleClick()}} disabled={disabled}>
      <i className={labelType}>{label}</i>
    </button>
  );
};