import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { toast } from "react-hot-toast";
import Loader from "../../layout/Loader.jsx";
import HeaderAdmin from "../../layout/HeaderAdmin.jsx";
import MetaData from "../../layout/MetaData.jsx";

import { useAdminGenerateDocumentSignedURLQuery } from "../../../redux/api/orderAdminApi.js";

const ViewUploadedDocument = () => {
  const params = useParams();
  const documentId = params?.id;

  const { data: documentURLData, error: errorLoadingDocumentURL } = useAdminGenerateDocumentSignedURLQuery({documentId}, { refetchOnMountOrArgChange: true });

  useEffect(() => {
      if (errorLoadingDocumentURL) {
        toast.error(errorLoadingDocumentURL?.data?.message);
      } 
  }, [errorLoadingDocumentURL]);

  useEffect(() => {

    if (documentURLData && documentURLData.url) {
      window.location.href = documentURLData.url;
    }
  }, [documentURLData]);

  return (
    <>
      <MetaData title={"Uploaded Document "} />
      <HeaderAdmin />
      <Loader/>
    </>     
  );
};

export default ViewUploadedDocument;