import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { getBackendURI, getQueryCorsMode } from "./helper.js";

export const orderApi = createApi({
  reducerPath: "orderApi",
  baseQuery: fetchBaseQuery(
    { 
      baseUrl: getBackendURI("/api/v1"),
      mode: getQueryCorsMode(),
      credentials: 'include', // Include cookies in the request
    }),
  tagTypes: ["Order","OldOrder"],
  endpoints: (builder) => ({
    createNewOrder: builder.mutation({
      query(body) {
        return {
          url: "/orders/new",
          method: "POST",
          body,
        };
      },
      invalidatesTags: ["Order"],
    }),
    myOrders: builder.query({
      query: () => `/me/orders`,
      providesTags: ["Order"],
    }),
    myOldOrders: builder.query({
      query: (params) => ({
        url: `/me/oldorders`,
        params: {
          page: params?.page,
          startDate: params?.startDate,
          endDate: params?.endDate,
        },
      }),
      providesTags: ["OldOrder"],
    }),    
    orderDetails: builder.query({
      query: ({id, extraParams}) => `/me/orders/${id}?extraParams=${extraParams}`,
      providesTags: ["Order"],
    }),
    // Update order
    updateOrder: builder.mutation({
      query({ id, body }) {
        return {
          url: `/me/orders/${id}`,
          method: "PUT",
          body,
        };
      },
      invalidatesTags: ["Order"],
    }),
    // Delete order
    deleteOrder: builder.mutation({
      query(id) {
        return {
          url: `/me/orders/${id}`,
          method: "DELETE",
        };
      },
      invalidatesTags: ["Order"],
    }),
    oldOrderDetails: builder.query({
      query: ({id, extraParams}) => `/me/oldorders/${id}?extraParams=${extraParams}`,
      providesTags: ["OldOrder"],
    }),
    stripeCheckoutSession: builder.mutation({
      query(body) {
        return {
          url: "/payment/checkout_session",
          method: "POST",
          body,
        };
      },
    }),
  }),
});

export const {
  useCreateNewOrderMutation,
  useMyOrdersQuery,
  useLazyMyOldOrdersQuery,
  useOrderDetailsQuery,
  useDeleteOrderMutation,
  useUpdateOrderMutation,
  useOldOrderDetailsQuery,
  useStripeCheckoutSessionMutation,
} = orderApi;
