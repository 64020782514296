import React from "react";
import MetaData from "../layout/MetaData";
import { Link, useLocation } from "react-router-dom";

const HomePageLayout = ({ children, title }) => {
    const location = useLocation();

    const getTabStyle = (path) => ({
        fontSize: '1.2em',
        padding: '10px 20px',
        textDecoration: 'none',
        color: location.pathname === path || (path === '/shop' && location.pathname === '/login')  ? '#fff' : '#000',
        backgroundColor: location.pathname === path || (path === '/shop' && location.pathname === '/login') ? '#007bff' : '#f0f0f0',
        borderRadius: '20px'
    });

    console.log('path', location.pathname);

    return (
        <>
            <MetaData title={title} />
            <header className="top-row" style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', padding: '10px 20px' }}>
                <div className="logo">
                    <Link to="/">
                        <img src="/images/TomProduce_logo.jpg" alt="Tomproduce Logo" style={{ height: '15vh', width: 'auto' }} />
                    </Link>
                </div>
                <nav className="tabs" style={{ display: 'flex', gap: '20px' }}>
                    <Link to="/" className="tab" style={getTabStyle('/')}>Home</Link>
                    <Link to="/categories" className="tab" style={getTabStyle('/categories')}>Products</Link>
                    <Link to="/contact" className="tab" style={getTabStyle('/contact')}>Contacts</Link>
                    <Link to="/privacy" className="tab" style={getTabStyle('/privacy')}>Privacy</Link>
                    <Link to="/shop" className="tab" style={getTabStyle('/shop')}>Shop</Link>
                </nav>
            </header>

            <div style={{ position: 'relative', textAlign: 'center', marginTop: '20px' }}>
            <div
                style={{
                width: '100%',
                height: '100%',
                position: 'absolute',
                top: 0,
                left: 0,
                zIndex: -1,
                backgroundImage: 'url(/images/splash.jpg)',
                backgroundSize: 'cover',
                backgroundPosition: 'center',
                filter: 'brightness(20%)',
                }}
            ></div>
            <div style={{ position: 'relative', zIndex: 1, padding: '20px', color: 'white' }}>
                {children}
            </div>
            </div>
        </>
    );
};

export default HomePageLayout;