import HomePageLayout from "../layout/HomePageLayout";
import { COMPANY_ADDRESS_FAX, COMPANY_ADDRESS_LINE1, COMPANY_ADDRESS_LINE2, COMPANY_ADDRESS_PHONE, COMPANY_EMAIL } from "../../constants/constants";

const Contact = () => {
    return (
        <HomePageLayout title="Contact Us">
            <div style={{ padding: '20px', textAlign: 'left', fontSize: '1.2em', lineHeight: '1.4', marginTop: '20px' }}>
                <h1>Contact Us</h1>
                <p></p>
                <p>If you have any questions or need further information, please feel free to contact us. We are here to help!</p>
                
                <div style={{ marginTop: '30px' }}>
                    <h2>Contact Information</h2>
                    <p></p>
                    <p><strong>Address:</strong> {COMPANY_ADDRESS_LINE1}</p>
                    <p><strong>Address:</strong> {COMPANY_ADDRESS_LINE2}</p>
                    <p><strong>Phone:</strong> {COMPANY_ADDRESS_PHONE}</p>
                    <p><strong>Fax:</strong> {COMPANY_ADDRESS_FAX}</p>
                    <p><strong>Email:</strong> {COMPANY_EMAIL}</p>
                </div>
            </div>
        </HomePageLayout>
    );
};

export default Contact;