import React, { useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";

const Search = () => {
    const navigate = useNavigate();
    let [searchParams] = useSearchParams();
    const currentKeyword = searchParams.get("keyword");
    const [keyword, setKeyword] = useState(currentKeyword ? currentKeyword : "");

    const submitHandler = (e) => {
        e.preventDefault();  
        
        const trimmedKeyword = keyword?.trim();
        if (trimmedKeyword) {
            searchParams.set("keyword", trimmedKeyword);
        } else {
            searchParams.delete("keyword");
        }
        // Remove page number when searching
        searchParams.delete("page");
        navigate(`/shop?` + searchParams.toString());
    };

    return (
        <form onSubmit={submitHandler}>
            <div className="input-group">
                <input
                    type="text"
                    id="search_field"
                    aria-describedby="search_btn"
                    className="form-control"
                    placeholder="Enter Product Name ..."
                    name="keyword"
                    value={keyword}
                    onChange={(e) => setKeyword(e.target.value)}
                />
                <button id="search_btn" className="btn" type="submit">
                    <i className="fa fa-search" aria-hidden="true"></i>
                </button>
                <button 
                    className="btn btn-outline-secondary" 
                    type="button" 
                    onClick={() => { setKeyword("");  navigate(`/shop`)}}
                >
                    Clear
                </button>
            </div>
        </form>
    );
};

export default Search;
