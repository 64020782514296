import React, { useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import { useSelector } from "react-redux";

import Loader from "../layout/Loader.jsx";
import { toast } from "react-hot-toast";
import MetaData from "../layout/MetaData.jsx";
import { useOldOrderDetailsQuery } from "../../redux/api/orderApi.js";
import { ORDER_STATUS, PAYMENT_TYPE, PAYMENT_STATUS } from "../../constants/constants.js";
import Header from "../layout/Header.jsx";

const OldOrderDetails = () => {
  const { customer } = useSelector((state) => state.customerAuth);
  const params = useParams();
  const { data, isLoading, error } = useOldOrderDetailsQuery({id: params?.id}, { refetchOnMountOrArgChange: true });
  const order = data?.order || {};

  const {
    orderNumber,
    addressInfo,
    orderItems,
    paymentInfo,
    totalAmount,
    status,
  } = order;

  const isPaid = paymentInfo?.status === "paid" ? true : false;

  /*
  useEffect(() => {
    localStorage.setItem('lastCustomerVisitedPage', window.location.pathname);
  }, []);
  */

  useEffect(() => {
    if (error) {
      toast.error(error?.data?.message);
    }
  }, [error]);

  if (isLoading) return <Loader />;

  return (
    <>
      <MetaData title={"Order Details"} />
      <Header />
      <div className="container">
        <div className="row d-flex justify-content-center">
          <div className="col-12 col-lg-9 mt-5 order-details">
            <div className="d-flex justify-content-between align-items-center">
              <h3 className="mt-5 mb-4">Your Order Details</h3>
              <Link to={`/invoice/oldorder/${order?._id}`} className="btn btn-success">
                <i className="fa fa-print"></i>&nbsp;Invoice
              </Link>
            </div>
            <table className="table table-striped table-bordered">
              <tbody>
                <tr>
                  <th scope="row">Order #</th>
                  <td><strong>{order?.orderNumber}</strong></td>
                </tr>
                <tr>
                  <th scope="row">Status</th>
                  <td
                    className={
                      String(status).includes(ORDER_STATUS.DELIVERED)
                        ? "greenColor"
                        : "redColor"
                    }
                  >
                    <b>{String(status).toLocaleUpperCase()}</b>
                  </td>
                </tr>
                <tr>
                  <th scope="row">Date</th>
                  <td>{new Date(order?.createdAt).toLocaleString("en-US")}</td>
                </tr>
              </tbody>
            </table>

            <h3 className="mt-5 mb-4">Shipping Info</h3>
            <table className="table table-striped table-bordered">
              <tbody>
                <tr>
                  <th scope="row">Name</th>
                  <td>{customer?.name}</td>
                </tr>
                <tr>
                  <th scope="row">Phone No</th>
                  <td>{addressInfo?.phone}</td>
                </tr>
                <tr>
                  <th scope="row">Shipping Address</th>
                  <td>
                    {addressInfo?.address}
                  </td>
                </tr>
                <tr>
                  <th scope="row">Billing Address</th>
                  <td>
                    {addressInfo?.billingAddress}
                  </td>
                </tr>
              </tbody>
            </table>

{/*
            <h3 className="mt-5 mb-4">Payment Info</h3>
            <table className="table table-striped table-bordered">
              <tbody>
                <tr>
                  <th scope="row">Status</th>
                  <td className={isPaid ? "greenColor" : "redColor"}>
                    <b>{String(paymentInfo?.status).toUpperCase()}</b>
                  </td>
                </tr>
                <tr>
                  <th scope="row">Method</th>
                  <td>{String(paymentInfo?.paymentType).toUpperCase()}</td>
                </tr>
                <tr>
                  <th scope="row">Invoice #</th>
                  <td>{orderNumber}</td>
                </tr>
                <tr>
                  <th scope="row">Total Amount</th>
                  <td>${totalAmount?.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</td>
                </tr>
                
                <tr>
                  <th scope="row">Amount Paid</th>
                  <td>{paymentInfo?.amount ? "$" + paymentInfo.amount.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 }) : "" }</td>
                </tr>
                
              </tbody>
            </table>
*/}
            <h3 className="mt-5 my-4">Order Items:</h3>

            <table className="table tight-table" style={{ border: '1px solid #000', borderCollapse: 'collapse', padding: '8px'}}>
              <thead>
                <tr>
                  <th style={{ width: '8%',  border: '1px solid #000', textAlign: 'center', padding: '10px'}}>#</th>
                  <th style={{ width: '45%', border: '1px solid #000', textAlign: 'center', padding: '10px' }}>Item</th>
                  <th style={{ width: '13%', border: '1px solid #000', textAlign: 'center', padding: '10px' }}>Price Each</th>
                  <th style={{ width: '13%', border: '1px solid #000', textAlign: 'center', padding: '10px' }}>Quantity</th>
                  <th style={{ width: '14%', border: '1px solid #000', textAlign: 'center', padding: '10px' }}>Amount</th>
                </tr>
              </thead>
              <tbody>
                {orderItems.map((item, index) => (
                  <tr key={index} style={{ border: 'none' }}>
                    <td style={{ textAlign: 'right', borderLeft: '1px solid #000', borderRight: '1px solid #000', borderTop: 'none', borderBottom: 'none', padding: '10px'}}>
                      {index + 1}
                    </td>
                    <td style={{ borderLeft: '1px solid #000', borderRight: '1px solid #000', borderTop: 'none', borderBottom: 'none', paddingLeft: '10px', padding: '5px' }}>
                      {item.name}
                    </td>
                    <td style={{ textAlign: 'right', borderLeft: '1px solid #000', borderRight: '1px solid #000', borderTop: 'none', borderBottom: 'none', padding: '5px' }}>
                      {item.finalPrice}
                    </td>
                    <td style={{ textAlign: 'right', borderLeft: '1px solid #000', borderRight: '1px solid #000', borderTop: 'none', borderBottom: 'none', padding: '5px' }}>
                        {item.finalQuantity}
                      </td>
                    <td style={{ textAlign: 'right', borderLeft: '1px solid #000', borderRight: '1px solid #000', borderTop: 'none', borderBottom: 'none', padding: '5px' }}>
                      {item && item.finalPrice && item.finalQuantity !== undefined ? ((item.finalPrice * item.finalQuantity)?.toFixed(2)) : ' '}
                    </td>
                  </tr>
                ))}

                <tr>
                  <td colSpan="6" style={{ border: '1px solid #000', fontSize: '1.5em', verticalAlign: 'middle' , paddingLeft: '10px', paddingRight: '10px'}}>
                    <b>TOTAL</b>
                    <span style={{ float: 'right' }}>${order?.totalAmount}</span>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </>
  );
};

export default OldOrderDetails;
