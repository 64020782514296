const CategoryForHomePage = ({ product, customer }) => {

    const productImage = product.image;

    return (
        <div className={`col-3 my-3`}>
            <div className="card p-3 rounded">
                <img
                    className="card-img-top mx-auto"
                    src={productImage}
                    alt=""
                />
                <div className="card-body ps-3 d-flex justify-content-center flex-column">
                    <h5 className="card-title text-center">
                        {product?.name} {product?.otherName ? ` - ${product.otherName}` : ''}
                    </h5>
                </div>
            </div>
        </div>
    );
};

export default CategoryForHomePage;
