import React, { useEffect, useState } from "react";
import { toast } from "react-hot-toast";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import _ from "lodash";

import MetaData from "../../layout/MetaData";
import Loader from "../../layout/Loader";
import AdminLayout from "../../layout/AdminLayout";
import HeaderAdmin from "../../layout/HeaderAdmin";
import { useAdminCreateCustomerMutation, useAdminGetAllUsersQuery } from "../../../redux/api/adminApi";
import { ADMIN_MENU_ITEMS, CUSTOMER_STATUS, US_STATES } from "../../../constants/constants.js";
import { useLazyAdminLogoutQuery } from "../../../redux/api/adminAuthApi";
import { validateUserPassword, validateCustomerProfileForm } from "../../../utils/validators";

const UpdateCustomer = () => {
  const [name, setName] = useState("");
  const [addressInfo, setAddressInfo] = useState({
    address: '',
    billingAddress: ''
  });
  const [copyAddress, setCopyAddress] = useState(false);
  const [status, setStatus] = useState(CUSTOMER_STATUS.ACTIVE);
  const [email, setEmail] = useState("");
  const [tier, setTier] = useState(1);
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [salesRep, setSalesRep] = useState("");

  const navigate = useNavigate();
  const params = useParams();

  const { loadingAdmin, user } = useSelector((state) => state.adminAuth);

  const [adminCreateCustomer, { error, isSuccess, isLoading: creatingCustomer }] = useAdminCreateCustomerMutation();
  const [adminLogout] = useLazyAdminLogoutQuery();
  const { data: allUserData, isLoading: isLoadingAllUserData, error: errorLoadingAllUserData } = useAdminGetAllUsersQuery();

  useEffect(() => {
    if (error) {
      toast.error(error?.data?.message);
      if (error.status === 401) {
        adminLogout();
      }
    }

    if (isSuccess) {
      toast.success("Customer Created");
      navigate("/admin/customers");
    }
  }, [error, isSuccess]);

  const submitHandler = (e) => {
    e.preventDefault();

    const customer = {
      name,
      email,
      status,
      tier,
      addressInfo,
      username,
      password,
      confirmPassword,
      salesRep,
      salesRepName: _.find(allUserData, { _id: salesRep }).name
    };
    const { formIsValid, formErrors } = validateCustomerProfileForm(customer);
    const { passwordIsValid, passwordErrors } = validateUserPassword(customer);

    if (formIsValid && passwordIsValid) {
      adminCreateCustomer({ body:  customer});
    } else {
      Object.values(formErrors).forEach((error) => {
        toast.error(error);
      });
      Object.values(passwordErrors).forEach((error) => {
        toast.error(error);
      });
    }
  };

  const updateAddressHandler = (e) => {
    const { name, value } = e.target;
    setAddressInfo({
        ...addressInfo,
        [name]: value
    });
  };

  const handleCopyAddressChange = (e) => {
    setCopyAddress(e.target.checked);
    if (e.target.checked) {
        setAddressInfo({
            ...addressInfo,
            billingAddress: addressInfo.address
        });
    }
  };

  if (loadingAdmin || isLoadingAllUserData) {
    return <Loader />;
  } 

  return (
    <>
      <MetaData title={"New Customer"} />
      <HeaderAdmin title={"New Customer"} />
      <AdminLayout menuItem={ADMIN_MENU_ITEMS.CUSTOMERS.name}>
        <div className="row wrapper">
          <div className="col-10 col-lg-8">
            <form className="shadow-lg" onSubmit={submitHandler}>
            <div className="mb-3">
              <div className="row">
                <div className="col-4">
                  <label htmlFor="status_field" className="form-label">
                    Status
                  </label>
                  <select
                    id="status_field"
                    className="form-select"
                    name="status"
                    value={status}
                    onChange={(e) => setStatus(e.target.value)}
                  >
                    <option value={CUSTOMER_STATUS.ACTIVE}>{String(CUSTOMER_STATUS.ACTIVE).toUpperCase()}</option>
                    <option value={CUSTOMER_STATUS.PENDING}>{String(CUSTOMER_STATUS.PENDING).toUpperCase()}</option>
                    <option value={CUSTOMER_STATUS.INACTIVE}>{String(CUSTOMER_STATUS.INACTIVE).toUpperCase()}</option>
                  </select>
                </div>
                <div className="col-4">
                  <label htmlFor="name_field" className="form-label">
                    Tier
                  </label>
                  <select
                    id="tier_field"
                    className="form-select"
                    name="tier"
                    value={tier}
                    onChange={(e) => setTier(e.target.value)}
                  >
                    <option value={1}>Tier 1</option>
                    <option value={2}>Tier 2</option>
                    <option value={3}>Tier 3</option>
                  </select>
                </div>
                <div className="col-4">
                  <label htmlFor="name_field" className="form-label">
                    Sales Rep.
                  </label>
                  <select
                    id="salesrep_field"
                    className="form-select"
                    name="salesrep"
                    value={salesRep}
                    onChange={(e) => setSalesRep(e.target.value)}
                  >
                    {allUserData && allUserData.map((rep) => (
                      <option key={rep._id} value={rep._id}>
                        {rep.name}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
              <div className="mb-3">
                <label htmlFor="name_field" className="form-label">
                  Name
                </label>
                <input
                  type="name"
                  id="name_field"
                  className="form-control"
                  name="name"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                />
              </div>

              {/* Email */}
              <div className="mb-3">
                <label htmlFor="email_field" className="form-label">
                  Email (multiple emails are separated by comma)
                </label>
                <input
                  type="text"
                  id="email_field"
                  className="form-control"
                  name="email"
                  value={email}
                  onChange={(e) => {setEmail(e.target.value)}}
                />  
              </div>

              {/* Shipping Address */}
              <div className="mb-3">
                <label htmlFor="address_field" className="form-label">
                  Shipping Address
                </label>
                <input
                  type="text"
                  id="address_field"
                  className="form-control"
                  name="address"
                  value={addressInfo.address}
                  onChange={(e) => { updateAddressHandler(e) }}
                />
              </div>

              {/* Billing Address */}
              <div className="d-flex justify-content-between align-items-center">
                  <label htmlFor="address_field" className="form-label">
                    Billing Address
                  </label>
                  <div className="d-flex align-items-center">
                    <label className="form-check-label" htmlFor="copy_address_checkbox">
                        Copy Shipping Address to Billing Address
                    </label>
                    &nbsp;
                    <input
                        type="checkbox"
                        className="form-check-input me-2"
                        id="copy_address_checkbox"
                        checked={copyAddress}
                        onChange={handleCopyAddressChange}
                    />
                </div>
              </div>
              <div className="mb-3">
                <input
                  type="text"
                  id="billing_address_field"
                  className="form-control"
                  name="billingAddress"
                  value={addressInfo.billingAddress}
                  onChange={(e) => { updateAddressHandler(e) }}
                  disabled={copyAddress} 
                />
              </div>

              <div className="row">
                {/* Phone Number */}
                <div className="col-6 col-lg-6">
                  <label htmlFor="phone_field" className="form-label">
                    Phone
                  </label>
                  <input
                    type="tel"
                    id="phone_field"
                    className="form-control"
                    name="phone"
                    value={addressInfo.phone}
                    pattern="^\(?\d{3}\)?[-.\s]?\d{3}[-.\s]?\d{4}$"
                    onInvalid={(e) => {
                      e.target.setCustomValidity('Please enter a valid phone number');
                    }}
                    onInput={(e) => {
                      e.target.setCustomValidity('');
                    }}
                    onChange={(e) => { updateAddressHandler(e) }}
                  />
                </div>
                {/* Fax Number */}
                <div className="col-6 col-lg-6">
                  <label htmlFor="fax_field" className="form-label">
                    Fax
                  </label>
                  <input
                    type="tel"
                    id="fax_field"
                    className="form-control"
                    name="fax"
                    value={addressInfo.fax}
                    pattern="^\(?\d{3}\)?[-.\s]?\d{3}[-.\s]?\d{4}$"
                    onInvalid={(e) => {
                      e.target.setCustomValidity('Please enter a valid fax number');
                    }}
                    onInput={(e) => {
                      e.target.setCustomValidity('');
                    }}
                    onChange={(e) => { updateAddressHandler(e) }}
                  />
                </div>
              </div>
              
              {/* Username */}
              <div className="mb-3">
                <label htmlFor="username_field" className="form-label">
                  Username (leave blank to disable username login)
                </label>
                <input
                  type="username"
                  id="username_field"
                  className="form-control"
                  name="username"
                  value={username}
                  onChange={(e) => {setUsername(e.target.value)}}
                />  
              </div>
              <div className="row">
                {/* Password */}
                <div className="col-6 col-lg-6">
                  <label htmlFor="password_field" className="form-label">
                    Password (leave blank to disable password login)
                  </label>
                  <input
                    type={showPassword ? "text" : "password"}
                    id="password_field"
                    className="form-control"
                    name="password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}         
                  />
                  <p className="password-rule" style={{ color: "lightgray", fontWeight: "thin" }}>
                    Password must be at least 8 characters long
                  </p>
                </div>

                {/* Confirm Password */}
                <div className="col-6 col-lg-6">
                  <label htmlFor="confirm_password_field" className="form-label">
                    Confirm Password
                  </label>
                  <input
                    type={showPassword ? "text" : "password"}
                    id="confirm_password_field"
                    className="form-control"
                    name="confirmPassword"
                    value={confirmPassword}
                    onChange={(e) => setConfirmPassword(e.target.value)}    
                  />
                </div>
              </div>
              <span className="password-toggle-icon" onClick={() => setShowPassword(!showPassword)}>
                {showPassword ? <i className="fas fa-eye-slash"></i> : <i className="fas fa-eye"></i>}
              </span>
              <br />
              <button disabled={creatingCustomer} type="submit" className="btn update-btn w-100 py-2">
                Create
              </button>
            </div>  
            </form>
          </div>
        </div>
      </AdminLayout>
      </>
  );
};

export default UpdateCustomer;
