import React from "react";
import MetaData from "../layout/MetaData.jsx";
import { PRODUCT_CATEGORIES } from "../../constants/constants.js";

import CategoryForHomePage from "../product/CategoryForHomePage.jsx";
import HomePageLayout from "../layout/HomePageLayout.jsx";

const Products = () => {
    return (
        <HomePageLayout title="Shop Produce Online">
            <MetaData title={"Shop Produce Online"} />
            <div className="col-12"  style={{width: '100%'}}> 
                <section id="products" className="mt-5">
                    <div className="row">
                        {PRODUCT_CATEGORIES.map((product) => (
                            <CategoryForHomePage key={product._id} product={product}/>
                        ))}
                    </div>
                </section>
            </div>
            <div className="row">
                <div className="col-12">
                    <h3>
                        Please log in or contact us for complete list of products!
                    </h3>
                </div>
            </div>
        </HomePageLayout>
    );
};


export default Products;