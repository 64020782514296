import React,  { useEffect, useState } from "react";
import html2canvas from "html2canvas";
import { jsPDF } from "jspdf";
//import { PDFDocument } from 'pdf-lib';

import { useLazyAdminOrderDetailsQuery, useLazyAdminOldOrderDetailsQuery } from "../../../redux/api/orderAdminApi";
import Loader from "../../layout/Loader";
import { useLocation, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import toast from "react-hot-toast";
import PrintInvoice from "../../printings/Invoice";
import HeaderAdmin from "../../layout/HeaderAdmin";
import MetaData from "../../layout/MetaData";

const AdminInvoice = () => {
    const isOldOrder = window.location.href.includes('oldinvoice');

    const params = useParams();
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const orderId = params?.id;
    

    const [order, setOrder] = useState(null); // Order details

    const [ adminOrderDetail, { isLoading: loadingOrderDetails, data: orderDetails, error: loadingOrderDetailError }]  = useLazyAdminOrderDetailsQuery();
    const [ adminOldOrderDetail, { isLoading: loadingOldOrderDetails, data: oldOrderDetails, error: loadingOldOrderDetailError }]  = useLazyAdminOldOrderDetailsQuery();

    const { loadingAdmin, user } = useSelector((state) => state.adminAuth);

    useEffect(() => {
      if (isOldOrder) {
        adminOldOrderDetail({ id: orderId, extraParams: 'fullProductDetail,confirmedBy'});
      } else {
        adminOrderDetail({ id: orderId, extraParams: 'fullProductDetail,confirmedBy'});
      }
    }, []);

    useEffect(() => {
        if (user && orderDetails?.order) {
            const order = orderDetails.order;
            setOrder(order);
        }
        if (user && oldOrderDetails?.order) {
          const order = oldOrderDetails.order;
          setOrder(order);
        }
    }, [user, orderDetails, oldOrderDetails]);

    useEffect(() => {
        if (loadingOrderDetailError) {
          toast.error(loadingOrderDetailError?.data?.message);
        } 
        if (loadingOldOrderDetailError) {
          toast.error(loadingOldOrderDetailError?.data?.message);
        } 
    }, [loadingOrderDetailError, loadingOldOrderDetailError]);

    return (
        <>
          <MetaData title={"Order Invoice"} />
          <HeaderAdmin />

          {(loadingAdmin || loadingOrderDetails || loadingOldOrderDetails) && (<Loader/>)}
          {order && (
            <div style={{width: '95vw', display: 'flex', justifyContent: 'center'}}>
              <PrintInvoice order={order} />
            </div>
          )}
        </>
      );
};

export default AdminInvoice;