import Select from 'react-select';

export const VendorSelection = ({ vendors, onChange, defaultVendor }) => {
    const options = vendors?.map(vendor => ({
        value: vendor,
        label: vendor.name,
    }));

    const customStyles = {
        control: (provided) => ({
            ...provided,
            color: 'green',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
        }),
        menuPortal: (base) => ({ ...base, zIndex: 5 }),
    };

    const defaultOption = options?.find(option => option.value === defaultVendor);

    return (
        <div>
            <Select 
                onChange={(selectedOption) => {onChange(selectedOption.value)}}
                options={options} 
                isSearchable
                styles={customStyles}
                menuPortalTarget={document.body} // Add this line
                menuPosition={'fixed'} // Add this line
                maxMenuHeight={'50vh'}
                defaultValue={defaultOption} 
            /> 
        </div>
    );
};