import React, { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";

import Loader from "../layout/Loader.jsx";
import { toast } from "react-hot-toast";
import { useOrderDetailsQuery } from "../../redux/api/orderApi.js";
import { setCartItem, clearCart } from "../../redux/features/cartSlice.js";

const CloneOrder = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const params = useParams();
  const { data, error } = useOrderDetailsQuery({id: params?.id, extraParams: 'fullProductDetail,confirmedBy'});

  useEffect(() => {
    if (error) {
      toast.error(error?.data?.message);
    }
  }, [error]);

  useEffect(() => {
    dispatch(clearCart());
    if (data && data.order && data.order.orderItems) {
      const orderItems = data.order.orderItems;
      orderItems.forEach(item => {
        dispatch(setCartItem({
          product: item.product._id,
          name: item.name,
          finalPrice: item.finalPrice,
          image: item.product.images && item.product.images.length > 0 ? item.product.images[0].url : undefined,
          palletSize: item.product.palletSize,
          finalQuantity: item.finalQuantity,
        }));
      });

      navigate("/cart/", { replace: true });
    }
  }, [data]);

  
  return <Loader />;
  
};

export default CloneOrder;
