import React, { useEffect, useState } from "react";
import { toast } from "react-hot-toast";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import _, { set } from "lodash";

import MetaData from "../../layout/MetaData";
import Loader from "../../layout/Loader";
import AdminLayout from "../../layout/AdminLayout";
import {
  useAdminGetDriverDetailsQuery,
  useAdminUpdateDriverMutation,
  useAdminResetDriverPasswordMutation,
} from "../../../redux/api/adminApi";
import HeaderAdmin from "../../layout/HeaderAdmin";
import { ADMIN_MENU_ITEMS, USER_STATUS, USER_ROLES } from "../../../constants/constants.js";
import { useLazyAdminLogoutQuery } from "../../../redux/api/adminAuthApi";
import { CanUpdateDriver } from "../AdminActionEligibilities";
import { validateAdminProfileForm, validateUserPassword } from "../../../utils/validators.js";

const UpdateDriver = () => {
  const [name, setName] = useState("");
  const [username, setUsername] = useState("");
  const [status, setStatus] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");

  const navigate = useNavigate();
  const params = useParams();

  const { loadingAdmin, user } = useSelector((state) => state.adminAuth);
  const { data, isLoading, error: errorLoadingDriverDetail } = useAdminGetDriverDetailsQuery(params?.id);

  const [adminUpdateDriver, { error, isSuccess, isLoading: isUpdatingDriver }] = useAdminUpdateDriverMutation();
  const [adminLogout] = useLazyAdminLogoutQuery();
  const [clonedDriverData, setClonedDriverData] = useState(null);
  const [formChanged, setFormChanged] = useState(false);

  // Reset password part
  const [clonedUsername, setClonedUsername] = useState(null);
  const [showPassword, setShowPassword] = useState(false);
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [adminResetDriverPassword, { data: dataResetDriverPassword, error: errorResettingDriverPassword, isSuccess: resettingDriverPasswordSuccess, isLoading: isResettingDriverPassword }] = useAdminResetDriverPasswordMutation();

  useEffect(() => {
    if (data?.driver) {
      const aDriver = data?.driver;
      setName(aDriver.name);
      setUsername(aDriver.username);
      setStatus(aDriver.status);
      setEmail(aDriver.email);
      setPhone(aDriver.phone);

      setClonedUsername(aDriver.username);
      setClonedDriverData(
      {
        name: aDriver.name, 
        status: aDriver.status, 
        email: aDriver.email,
        phone: aDriver.phone,
      });
    }
  }, [data]);

  useEffect(() => {
    const newDriverData = {
      name,
      status,
      email,
      phone,
    };
    setFormChanged(!_.isEqual(newDriverData, clonedDriverData));
  }, [name, username, status, email, phone, clonedDriverData]);

  useEffect(() => {
    if (errorLoadingDriverDetail) {
      toast.error(errorLoadingDriverDetail?.data?.message);
      if (errorLoadingDriverDetail.status === 401) {
        adminLogout();
      } else if (errorLoadingDriverDetail.status === 404) {
        navigate("/admin/drivers");
      }
    }
  }, [errorLoadingDriverDetail]);

  useEffect(() => {
    if (error) {
      toast.error(error?.data?.message);
    }

    if (errorResettingDriverPassword) {
      toast.error(errorResettingDriverPassword?.data?.message);
    }

    if (isSuccess) {
      toast.success("Driver Updated");
      //navigate("/admin/drivers");
    }

    if (resettingDriverPasswordSuccess) {
      setPassword("");
      setConfirmPassword("");
      if (dataResetDriverPassword) {
        setUsername(dataResetDriverPassword.username);
        setClonedUsername(dataResetDriverPassword.username);
      }
      toast.success("Driver Password Reset"); 
    }

  }, [error, errorResettingDriverPassword, isSuccess, resettingDriverPasswordSuccess]);

  const submitHandler = (e) => {
    e.preventDefault();

    if (formChanged) {
      const newDriverData = {
        name,
        status,
        email,
        phone,
      };
      const { formIsValid, formErrors } = validateAdminProfileForm(newDriverData);
      if (!formIsValid) {
        Object.values(formErrors).forEach((error) => {
          toast.error(error);
        });
      } else {  
        adminUpdateDriver({ id: params?.id, body: newDriverData });
      }
    }
  };

  const submitResetPasswordHandler = (e) => {
    e.preventDefault();
    const { passwordIsValid, passwordErrors } = validateUserPassword({ password, confirmPassword, username });
    if (!passwordIsValid) {
      Object.values(passwordErrors).forEach((error) => {
        toast.error(error);
      });
      return;
    }
    adminResetDriverPassword({ id: params?.id, body: { password, confirmPassword, username } });
  };

  if (isLoading || loadingAdmin) {
    return <Loader />;
  } 

  const canUpdateDriver = CanUpdateDriver(user);

  return (
    <>
      <MetaData title={"Driver Details"} />
      <HeaderAdmin title={"Driver Details"} />
      <AdminLayout menuItem={ADMIN_MENU_ITEMS.DRIVERS.name}>
        <div className="row wrapper">
          <div className="col-10 col-lg-8">
            <form className="shadow-lg" onSubmit={submitHandler}>
              <div className="row">
                <div className="col-6">
                  <label htmlFor="status_field" className="form-label">
                    Status
                  </label>
                  <select
                    id="status_field"
                    className="form-select"
                    name="status"
                    value={status}
                    disabled={!canUpdateDriver}
                    onChange={(e) => setStatus(e.target.value)}
                  >
                    <option value={USER_STATUS.ACTIVE}>{String(USER_STATUS.ACTIVE).toUpperCase()}</option>
                    <option value={USER_STATUS.INACTIVE}>{String(USER_STATUS.INACTIVE).toUpperCase()}</option>
                  </select>
                </div>
              </div>
              <div className="mb-3">
                <label htmlFor="name_field" className="form-label">
                  Name
                </label>
                <input
                  type="name"
                  id="name_field"
                  className="form-control"
                  name="name"
                  readOnly={!canUpdateDriver}
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                />
              </div>
              <div className="row">
                {/* Email */}
                <div className="col-6 col-lg-6">
                  <label htmlFor="email_field" className="form-label">
                    Email
                  </label>
                  <input
                    type="email"
                    id="email_field"
                    className="form-control"
                    name="email"
                    readOnly={!canUpdateDriver}
                    value={email}
                    onChange={(e) => {setEmail(e.target.value)}}
                  />  
                </div>
                {/* Phone Number */}
                <div className="col-6 col-lg-6">
                  <label htmlFor="phone_field" className="form-label">
                    Phone Number
                  </label>
                  <input
                    type="tel"
                    id="phone_field"
                    className="form-control"
                    name="phone"
                    readOnly={!canUpdateDriver}
                    value={phone}
                    pattern="^\(?\d{3}\)?[-.\s]?\d{3}[-.\s]?\d{4}$"
                    onInvalid={(e) => {
                      e.target.setCustomValidity('Please enter a valid phone number');
                    }}
                    onInput={(e) => {
                      e.target.setCustomValidity('');
                    }}
                    onChange={(e) => {setPhone(e.target.value) }}
                  />
                </div>
              </div>
              <br />
              
              { canUpdateDriver && (<button disabled={!formChanged || isUpdatingDriver} type="submit" className="btn update-btn w-100 py-2">
                Update
              </button>)}
            </form>
          </div>

          {/* Reset Password */}
          <div className="col-10 col-lg-8">
            <form className="shadow-lg" onSubmit={submitResetPasswordHandler}>
            <div className="mb-3">
              <label htmlFor="username_field" className="form-label">
                Username
              </label>
              <input
                type="text"
                id="username_field"
                className="form-control"
                name="username"
                value={username}
                onChange={(e) => setUsername(e.target.value)}
              />
            </div>
            <div className="row">
                {/* Password */}
                <div className="col-6 col-lg-6">
                  <label htmlFor="password_field" className="form-label">
                    Password
                  </label>
                  <input
                    type={showPassword ? "text" : "password"}
                    id="password_field"
                    className="form-control"
                    name="password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}         
                  />
                  <p className="password-rule" style={{ color: "lightgray", fontWeight: "thin" }}>
                    Password must be at least 8 characters long
                  </p>
                </div>

                {/* Confirm Password */}
                <div className="col-6 col-lg-6">
                  <label htmlFor="confirm_password_field" className="form-label">
                    Confirm Password
                  </label>
                  <input
                    type={showPassword ? "text" : "password"}
                    id="confirm_password_field"
                    className="form-control"
                    name="confirmPassword"
                    value={confirmPassword}
                    onChange={(e) => setConfirmPassword(e.target.value)}    
                  />
                </div>
              </div>
              <span className="password-toggle-icon" onClick={() => setShowPassword(!showPassword)}>
                {showPassword ? <i className="fas fa-eye-slash"></i> : <i className="fas fa-eye"></i>}
              </span>

              <button disabled={(isResettingDriverPassword || isUpdatingDriver || !password || !confirmPassword || password !== confirmPassword)  && username === clonedUsername } type="submit" className="btn update-btn w-100 py-2">
                Reset Login Credential
              </button>              
            </form>
          </div>
        </div>
      </AdminLayout>
      </>
  );
};

export default UpdateDriver;
