import React, { useEffect, useState } from "react";
import MetaData from "../layout/MetaData";
import toast from "react-hot-toast";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useCustomerLoginMutation } from "../../redux/api/customerAuthApi";
import Header from "../layout/Header";
import { useCustomerGetMeQuery } from "../../redux/api/customerApi";
import Loader from "../layout/Loader";
import HomePageLayout from "../layout/HomePageLayout";
import '@fortawesome/fontawesome-free/css/all.min.css';

const Login = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [login, { isLoading, error }] = useCustomerLoginMutation();
  const { isAuthenticated, accountLocked } = useSelector((state) => state.customerAuth);
  const { isLoading: isLoadingGetMeQuery } = useCustomerGetMeQuery();
  const navigate = useNavigate();

  useEffect(() => {
    if (isAuthenticated) {
      //let lastCustomerVisitedPage = localStorage.getItem('lastCustomerVisitedPage');
      //if (lastCustomerVisitedPage === undefined || lastCustomerVisitedPage === null || lastCustomerVisitedPage === "")
      const lastCustomerVisitedPage = `${process.env.REACT_APP_HOME_PAGE}`;
      navigate(lastCustomerVisitedPage);
    }
    if (error) {
      toast.error(error.data?.message);
    }
    if (accountLocked) {
      toast.error("Your account is locked. Please contact Tom Produce");
    }
  }, [isLoading, error, accountLocked, isAuthenticated]);

  const submitHandler = (e) => {
    e.preventDefault();

    const loginData = {
      email,
      password,
    };
    login(loginData);
  }

  if (isLoadingGetMeQuery || isLoading) return <Loader />;

  return (
    <HomePageLayout title="Shop Produce Online">
      <div className="container">
        <div className="row wrapper">
          <div className="col-10 col-lg-5">
            <form
              className="shadow rounded bg-body"
              onSubmit={submitHandler}
            >
              <h2 className="mb-4" style={{color: 'black'}}>Customer Login</h2>
              <div className="mb-3">
                <label htmlFor="email_field" className="float-start" style={{color: 'black'}}>Email</label>
                <input
                  type="email"
                  id="email_field"
                  className="form-control"
                  name="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  autoComplete="tom-produce-email"
                />
              </div>

              <div className="mb-3">
                <label htmlFor="password_field" className="float-start" style={{color: 'black'}}>Password</label>
                <input
                  type={showPassword ? "text" : "password"}
                  id="password_field"
                  className="form-control"
                  name="password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  autoComplete="tom-produce-password"
                />
                <span className="password-toggle-icon" onClick={() => setShowPassword(!showPassword)}>
                    {showPassword ? (
                        <i className="fas fa-eye-slash float-start" style={{ color: 'darkgray' }}></i>
                    ) : (
                        <i className="fas fa-eye float-start" style={{ color: 'darkgray' }}></i>
                    )}
                </span>
              </div>

              <a href="/password/forgot" className="float-end mb-4">Forgot Password?</a>

              <button 
                id="login_button" 
                type="submit" 
                className="btn w-100 py-2" 
                disabled={ isLoading }
              >
                { isLoading ? "Authenticating ..." : "LOGIN" }
              </button>

              <div className="my-3">
                <span style={{ color: 'darkgray' }}>New User? Please contact Tom Produce to create an account.</span>
              </div>
            </form>
          </div>
        </div>
      </div>
    </HomePageLayout>
  );
};

export default Login;
