import React, { useEffect, useState } from "react";
import MetaData from "../layout/MetaData";
import toast from "react-hot-toast";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useAdminLoginMutation } from "../../redux/api/adminAuthApi";
import Loader from "../layout/Loader";
import { useAdminGetMeQuery } from "../../redux/api/adminApi";

const AdminLogin = () => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const { isLoading: loadingMeQuery } = useAdminGetMeQuery();
  const [login, { isLoading, error, data }] = useAdminLoginMutation();
  const { isAuthenticatedAdmin, loadingAdmin, user} = useSelector((state) => state.adminAuth);
  const navigate = useNavigate();

  useEffect(() => {
    if (isAuthenticatedAdmin) {
      // Retrieve the last visited page from localStorage
      //let lastVisitedPage = localStorage.getItem('lastVisitedPage');
      //if (lastVisitedPage === undefined || lastVisitedPage === null || lastVisitedPage === "")
      const lastVisitedPage = `${process.env.REACT_APP_ADMIN_HOME_PAGE}`;
      navigate(lastVisitedPage);
    }
    if (error) {
      toast.error(error?.data?.message);
    }
  }, [error, isAuthenticatedAdmin]);

  const submitHandler = (e) => {
    e.preventDefault();

    const loginData = {
      username,
      password,
    };

    login(loginData);
  }

  if (loadingAdmin || loadingMeQuery) return <Loader />;

  return (
    <>
      <MetaData title={"Admin Login"} />
      <div className="row wrapper">
      <div className="col-10 col-lg-5">
        <form
          className="shadow rounded bg-body"
          onSubmit={submitHandler}
        >
          <h2 className="mb-4">Admin Login</h2>
          <div className="mb-3">
            <label htmlFor="username_field" className="form-label">Username</label>
            <input
              type="text"
              id="username_field"
              className="form-control"
              name="username"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
              autoComplete="tom-produce-username"
            />
          </div>

          <div className="mb-3">
            <label htmlFor="password_field" className="form-label">Password</label>
            <input
              type={showPassword ? "text" : "password"}
              id="password_field"
              className="form-control"
              name="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              autoComplete="tom-produce-password"
            />
            <span className="password-toggle-icon" onClick={() => setShowPassword(!showPassword)}>
                {showPassword ? <i className="fas fa-eye-slash"></i> : <i className="fas fa-eye"></i>}
              </span>
          </div>

          <a href="/admin/password/forgot" className="float-end mb-4">Forgot Password?</a>

          <button 
            id="login_button" 
            type="submit" 
            className="btn w-100 py-2" 
            disabled={ isLoading }
          >
            { isLoading ? "Authenticating ..." : "LOGIN" }
          </button>
{/*
          <div className="my-3">
            <a href="/register" className="float-end">New User?</a>
          </div>
*/}          
        </form>
      </div>
    </div>
    </>
  );
};

export default AdminLogin;
