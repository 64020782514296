import React, { useEffect, useState } from "react";
import { toast } from "react-hot-toast";

import MetaData from "../../layout/MetaData";
import AdminLayout from "../../layout/AdminLayout";
import { useNavigate } from "react-router-dom";
import { ADMIN_MENU_ITEMS } from "../../../constants/constants.js";
import { useAdminCreateProductMutation } from "../../../redux/api/productsAdminApi";
import { useLazyAdminLogoutQuery } from "../../../redux/api/adminAuthApi";
import { CategorySelection } from "../../product/CategorySelection";
import HeaderAdmin from "../../layout/HeaderAdmin";

const NewProduct = () => {
  const navigate = useNavigate();

  const [product, setProduct] = useState({
    sku: "",
    name: "",
    otherName: "",
    category: "",
    description: "",
    price: 0,
    palletSize: "",
    weight: "",
    origin: "",
    isActive: true,
  });
  const { sku, name, otherName, description, price, origin, palletSize, weight, isActive } = product;

  const [adminCreateProduct, { isLoading, error, isSuccess }] = useAdminCreateProductMutation();
  const [adminLogout] = useLazyAdminLogoutQuery();

  useEffect(() => {
    if (error) {
      toast.error(error?.data?.message);
      if (error?.status === 401) {
        adminLogout();
      }
    }

    if (isSuccess) {
      toast.success("Product created");
      navigate("/admin/products");
    }
  }, [error, isSuccess]);

  const onChange = (e) => {
    setProduct({ ...product, [e.target.name]: e.target.value });
  };

  const submitHandler = (e) => {
    e.preventDefault();
    if (!product.name) {
      toast.error("Please enter name");
      return;
    }
    if (!product.category) {
      toast.error("Please select a category");
      return;
    }
    adminCreateProduct(product);
  };

  return (
    <>
      <MetaData title={"New Product"} />
      <HeaderAdmin title={"New Product"} />
        <AdminLayout menuItem={ADMIN_MENU_ITEMS.PRODUCTS.name}>
        <div className="row wrapper">
          <div className="col-10 col-lg-10 mt-1 mt-lg-0">
            <form className="shadow rounded bg-body" onSubmit={submitHandler}>
              <div className="row my-0">
                <div className="col-4">
                  <label htmlFor="sku_field" className="form-label">
                    {" "}
                    SKU{" "}
                  </label>
                  <input
                    type="text"
                    id="sku_field"
                    className="form-control"
                    name="sku"
                    value={sku}
                    onChange={onChange}
                  />
                </div>
                <div className="col-5 offset-2">
                  <h3 className="mb-4">{`Active:  `} 
                    <input 
                      type="checkbox" 
                      name="isActive" 
                      checked={isActive} 
                      onChange={(e) => {setProduct({ ...product, isActive: e.target.checked });}} 
                      style={{ 
                        transform: 'scale(1.2)', 
                        marginRight: '20px',
                        marginLeft: '20px',
                      }} 
                    /> 
                  </h3>
                </div>
              </div>
              <div className="row my-4">
                <div className="col-6 col-lg-6">
                  <label htmlFor="name_field" className="form-label">
                    {" "}
                    Name{" "}
                  </label>
                  <input
                    type="text"
                    id="name_field"
                    className="form-control"
                    name="name"
                    value={name}
                    onChange={onChange}
                  />
                </div>
                <div className="col-6 col-lg-6">
                  <label htmlFor="name_field" className="form-label">
                    {" "}
                    Other Name{" "}
                  </label>
                  <input
                    type="text"
                    id="other_name_field"
                    className="form-control"
                    name="otherName"
                    value={otherName}
                    onChange={onChange}
                  />
                </div>
              </div>  
              <div className="row my-4">
                <div className="col-6 col-lg-6">
                  <label htmlFor="category_field" className="form-label">
                    {" "}
                    Category{" "}
                  </label>
                  <CategorySelection onChange={(selection) => {setProduct({ ...product, category: selection });}} />  
                </div>
                <div className="col-6 col-lg-6">
                  <label htmlFor="seller_field" className="form-label">
                    {" "}
                    Origin{" "}
                  </label>
                  <input
                    type="text"
                    id="origin_field"
                    className="form-control"
                    name="origin"
                    value={origin}
                    onChange={onChange}
                  />
                </div>
              </div>
              <div className="row my-4 ">
                <div className="col-4 col-lg-4">
                  <label htmlFor="price_field" className="form-label">
                    {" "}
                    Price{" "}
                  </label>
                  <input
                    type="number"
                    id="price_field"
                    className="form-control"
                    name="price"
                    value={price}
                    onChange={onChange}
                    onWheel={(e) => e.target.blur()}
                  />
                </div>
                <div className="col-4 col-lg-4">
                  <label htmlFor="pallet_size_field" className="form-label">
                    {" "}
                    Pallet Size{" "}
                  </label>
                  <input
                    type="number"
                    id="pallet_size_field"
                    className="form-control"
                    name="palletSize"
                    value={palletSize}
                    onChange={onChange}
                    onWheel={(e) => e.target.blur()}
                  />
                </div>
                <div className="col-4 col-lg-4">
                  <label htmlFor="weight_field" className="form-label">
                    {" "}
                    Weight
                  </label>
                  <input
                    type="text"
                    id="weight_field"
                    className="form-control"
                    name="weight"
                    value={weight}
                    onChange={onChange}
                    onWheel={(e) => e.target.blur()}
                  />
                </div>
              </div>
              <div className="mb-3">
                <label htmlFor="description_field" className="form-label">
                  Description
                </label>
                <textarea
                  className="form-control"
                  id="description_field"
                  rows="8"
                  name="description"
                  value={description}
                  onChange={onChange}
                ></textarea>
              </div>

              <button
                type="submit"
                className="btn w-100 py-2"
                disabled={isLoading}
              >
                {isLoading ? "Creating..." : "CREATE"}
              </button>
            </form>
          </div>
        </div>
      </AdminLayout>
    </>
  );
};

export default NewProduct;
