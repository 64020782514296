import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { toast } from "react-hot-toast";
import Loader from "../layout/Loader";

import { useOrderDetailsQuery, useOldOrderDetailsQuery } from "../../redux/api/orderApi";
import PrintInvoice from "../printings/Invoice.jsx";

export const CustomerInvoice = () => {
  const params = useParams();
  const { loading, customer } = useSelector((state) => state.customerAuth);
  const { data, isLoading, error } = useOrderDetailsQuery({id: params?.id, extraParams: 'fullProductDetail,confirmedBy'}, { refetchOnMountOrArgChange: true });
  const [order, setOrder] = useState(null);

  useEffect(() => {
    if (data) {
      //console.log('data', data);
      setOrder(data.order);
    }
  }, [data]);

  useEffect(() => {
    if (error) {
      toast.error(error?.data?.message);
    }
  }, [error]);


  const isOwner = customer?._id === order?.customer._id;

  return (
    <>
      {(loading || isLoading) && (<Loader/>)}
      {!isOwner && order && (<h1>Unauthorized!</h1>)}
      {isOwner && order && (
        <PrintInvoice order={order} />
      )}
    </>
  );
};

export const OldCustomerInvoice = () => {
  const params = useParams();
  const { loading, customer } = useSelector((state) => state.customerAuth);
  const { data, isLoading, error } = useOldOrderDetailsQuery({id: params?.id, extraParams: 'fullProductDetail,confirmedBy'}, { refetchOnMountOrArgChange: true });
  const [order, setOrder] = useState(null);

  useEffect(() => {
    if (data) {
      setOrder(data.order);
    }
  }, [data]);

  useEffect(() => {
    if (error) {
      toast.error(error?.data?.message);
    }
  }, [error]);


  const isOwner = customer?._id === order?.customer._id;

  return (
    <>
      {(loading || isLoading) && (<Loader/>)}
      {!isOwner && order && (<h1>Unauthorized!</h1>)}
      {isOwner && order && (
        <PrintInvoice order={order} />
      )}
    </>
  );
};
