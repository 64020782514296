import React, { useEffect, useState } from "react";
import { toast } from "react-hot-toast";
import { MDBDataTable } from "mdbreact";
import { Link, useSearchParams } from "react-router-dom";
import { useSelector } from "react-redux";

import Loader from "../../layout/Loader";
import MetaData from "../../layout/MetaData";
import {
  useAdminDeleteProductMutation,
  useAdminGetProductsQuery,
  useAdminUpdateProductMutation,
  useAdminUpdateMultipleProductPriceMutation,
} from "../../../redux/api/productsAdminApi";
import { useLazyAdminLogoutQuery } from "../../../redux/api/adminAuthApi";
import AdminLayout from "../../layout/AdminLayout";
import { ADMIN_MENU_ITEMS, PRODUCT_CATEGORIES } from "../../../constants/constants.js";
import HeaderAdmin from "../../layout/HeaderAdmin";
import { CanCreateProduct, CanDeleteProduct, CanUpdateProduct } from "../AdminActionEligibilities";
import { CenterAlignedCell, EditableNumberCell, RightAlignedCell } from "../../layout/CustomMDBDataTableCell";
import { valueIsANonNegativeNumber } from "../../../utils/validators.js";
import _ from "lodash";
import { calculateTargetPrice, getSellPrice } from "../../../utils/utilities.js";

const ListProducts = () => {
  // Redux
  const { loadingAdmin, user } = useSelector((state) => state.adminAuth);

  let [searchParams] = useSearchParams();
  const category = searchParams.get("category");
  const isActive = searchParams.get("isActive");
  const notOnPriceList = searchParams.get("notOnPriceList");
  const categoryName = category  ?  PRODUCT_CATEGORIES.find((cat) => cat.id === category)?.name : "All Products";
  const categoryOtherName = category  ? ` - ${PRODUCT_CATEGORIES.find((cat) => cat.id === category)?.otherName}`  : "";

  let extraParams = isActive === null  ? '' : (isActive === 'true' ? "activeOnly" : "inactiveOnly");
  if (notOnPriceList !== null) {
    const priceListParam = notOnPriceList === 'true' ? 'notOnPriceList' : 'onPriceList';
    extraParams = extraParams ? `${extraParams},${priceListParam}` : priceListParam;
  }
  const params = category !== null ? { category, extraParams  } : { extraParams };
  const { data, isLoading, error, refetch  } = useAdminGetProductsQuery(params, {key: Date.now()});
  const [clonedData, setClonedData] = useState();  
  const [dataChanged, setDataChanged] = useState({});
  const [formUpdated, setFormUpdated] = useState(false);

  const [adminLogout] = useLazyAdminLogoutQuery();

  const [adminDeleteProduct, { isLoading: isDeleteLoading, error: deleteError, isSuccess },] = useAdminDeleteProductMutation();
  const [updateProduct, { isLoading: isUpdatingProduct, error: errorUpdatingProduct, isSuccess: isSuccessUpdatingProduct }] = useAdminUpdateProductMutation();
  const [updateMultipleProductPrice, {isLoading: updatingMultipleProducts, error: errorUpdatingMultipleProducts, isSuccess: isSuccessUpdatingMultipleProducts}] = useAdminUpdateMultipleProductPriceMutation();
  /*
  useEffect(() => {
    localStorage.setItem('lastVisitedPage', window.location.pathname);
  }, []);
  */

  useEffect(() => {
    if (data) {
      // only refresh cloneData once
      if (!clonedData) {
        const sortedProducts = [...data.products].sort((a, b) => a.name.localeCompare(b.name));
        setClonedData(JSON.parse(JSON.stringify(sortedProducts)));
      }
    }
  }, [data]);
  
  useEffect(() => {
    if (deleteError) {
      toast.error(deleteError?.data?.message);
    }

    if (isSuccess) {
      toast.success("Product Deleted");
    }

    if (errorUpdatingMultipleProducts) {
      toast.error(errorUpdatingMultipleProducts?.data?.message);
    }

    if (errorUpdatingProduct) {
      toast.error(errorUpdatingProduct?.data?.message);
    }
  }, [deleteError, isSuccess, errorUpdatingMultipleProducts, errorUpdatingProduct]);

  useEffect(() => {
    if (error) {
      toast.error(error?.data?.message);
      if (error?.status === 401) {
        adminLogout();
      }
    }
  }, [error]);

  const deleteProductHandler = (id) => {
    adminDeleteProduct(id);
  };

  /*
  const updateProductHandler = (index) => {
    updateProduct({ id: clonedData[index]._id, body: clonedData[index]} );
  };
  */

  const updateMultipleProducstHandler = () => {
    setClonedData(null);
    const productUpdateList = [];
    // Iterate clonedData, only push the changed product
    clonedData.forEach((item, index) => {
      if (item.price !== data.products[index].price || item.notOnPriceList !== data.products[index].notOnPriceList) {
        productUpdateList.push(item);
      }
    });
    updateMultipleProductPrice({ body: {productUpdateList} });
  };

  const handleCategoryChange = (e) => {
    const category = e.target.value;
    if (category === "all") {
      searchParams.delete("category");
    } else {
      searchParams.set("category", category);
    }
    setClonedData(null);
    refetch();
  };

  const handleStatusChange = (e) => {
    const status = e.target.value;
    if (status === "all") {
      searchParams.delete("isActive");
    } else {
      searchParams.set("isActive", status);
    }
    setClonedData(null);
    refetch();
  };

  const handlePriceListFilterChange = (e) => {
    const priceListFilter = e.target.value;
    if (priceListFilter === "all") {
      searchParams.delete("notOnPriceList");
    } else {
      searchParams.set("notOnPriceList", priceListFilter);
    }
    setClonedData(null);
    refetch();
  };

  const handlePriceListChange = (e, index) => {
    clonedData[index] = {
      ...clonedData[index],
      notOnPriceList: !e.target.checked,
    };
    checkIfDataChanged(index);
  };


  // Handle price
  const handlePriceChange = (index, value) => {
    if (valueIsANonNegativeNumber(value) && clonedData[index].price !== value) {
      clonedData[index] = {
        ...clonedData[index],
        price: value,
      };
      checkIfDataChanged(index);
    }
  };

  // Handle pallet size
  const handlePalletSizeChange = (index, value) => {
    if (valueIsANonNegativeNumber(value) && clonedData[index].palletSize !== value) {
      clonedData[index] = {
        ...clonedData[index],
        palletSize: value,
      };
      checkIfDataChanged(index);
    }
  };

  const copyTargetPriceToPrice = (index) => {
    const targetPrice = clonedData[index].cost ? calculateTargetPrice(clonedData[index].cost) : '';
    clonedData[index] = {
      ...clonedData[index],
      price: targetPrice,
    };
    checkIfDataChanged(index);
  };

  const checkIfDataChanged = (index) => {
    const hasChanged = !_.isEqual(clonedData[index], data.products[index]);
    const productId = clonedData[index]._id;
    setDataChanged({ ...dataChanged, [productId]: hasChanged });
  };

  const setProducts = () => {
    const products = {
      columns: [
        {
          label: <CenterAlignedCell value={"SKU"}> </CenterAlignedCell>,
          field: "sku",
          sort: false,
        },
        {
          label: <CenterAlignedCell value={"Name"}> </CenterAlignedCell>,
          field: "name",
          sort: false,
        },
        {
          label: <CenterAlignedCell value={"Price"}> </CenterAlignedCell>,
          field: "price",
          sort: false,
        },
        {
          label: <CenterAlignedCell value={"Target Price"}> </CenterAlignedCell>,
          field: "targetPrice",
          sort: false,
          attributes: {
            style: {
              backgroundColor: 'lightgreen',
            },
          },
        },
        {
          label: <CenterAlignedCell value={"Cost"}> </CenterAlignedCell>,
          field: "cost",
          sort: false,
        },        
        {
          label: <CenterAlignedCell value={"Pallet"}> </CenterAlignedCell>,
          field: "pallet",
          sort: false,
        },        
        {
          label: <CenterAlignedCell value={"Price List"}> </CenterAlignedCell>,
          field: "priceList",
          sort: false,
        },   
        {
          label: <CenterAlignedCell value={"Status"}> </CenterAlignedCell>,
          field: "status",
          sort: false,
        },
        {
          label: <CenterAlignedCell value={"Actions"}> </CenterAlignedCell>,
          field: "actions",
          sort: false,
        },
      ],
      rows: [],
    };
    const canCreateProduct = CanCreateProduct(user);
    const canDeleteProduct = CanDeleteProduct(user);
    const canUpdateProduct = CanUpdateProduct(user);

    clonedData?.forEach((product, index) => {
      const priceColor = clonedData[index]?.price !== data.products[index]?.price ? "red" : "black";
      const palletSizeColor = clonedData[index]?.palletSize !== data.products[index]?.palletSize ? "red" : "black";
      const dataModified = dataChanged[product._id];
      const otherName = product?.otherName ? ` - ${product?.otherName} `: "";
      const targetPrice = product.cost ?  `$${calculateTargetPrice(product.cost)}` : '';
      //console.log("targetPrice", product.cost, targetPrice);
      products.rows.push({
        sku: product?.sku,
        name: `${product?.name} ${otherName}`,
        price: <EditableNumberCell readOnly={!canUpdateProduct} value={product.price ? `${product.price}` : ''} onChange={(val) => {handlePriceChange(index, val)}} color={priceColor}> </EditableNumberCell>,
        targetPrice: <RightAlignedCell color={'green'} value={targetPrice} />,
        cost:  <RightAlignedCell value={product.cost ? `$${product?.cost}`  : ''} />,
        pallet: <EditableNumberCell readOnly={!canUpdateProduct} value={product.palletSize} onBlur={(val) => {handlePalletSizeChange(index, val)}} color={palletSizeColor}> </EditableNumberCell>,
        priceList: (
          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <input
              id="priceList"
              type="checkbox"
              checked={!product.notOnPriceList}
              onChange={(e) => handlePriceListChange(e, index)}
              style={{ transform: 'scale(1.5)' }}
            />
          </div>
        ),
        status: product?.isActive ? "" : <span style={{ color: "red" }}>Inactive</span>,
        actions: (
          <center>
          {canCreateProduct && (
            <Link
              to={`/admin/products/${product?._id}`}
              className="btn btn-outline-primary"
            >
              <i className="fa fa-pencil"></i>
            </Link>          
          )}
          {canDeleteProduct && (
            <button
              className="btn btn-outline-danger ms-2"
              onClick={() => deleteProductHandler(product?._id)}
              disabled={isDeleteLoading || isUpdatingProduct || updatingMultipleProducts}
            >
              <i className="fa fa-trash"></i>
            </button>
          )}
          {/*canUpdateProduct && (
            <button
              className="btn btn-outline-success ms-2"
              onClick={() => updateProductHandler(index)}
              disabled={isDeleteLoading || isUpdatingProduct || !dataModified}
            >
              <i className="fa fa-save"></i>
            </button>
          )*/}
           <button
              className="btn btn-outline-success ms-2"
              onClick={() => copyTargetPriceToPrice(index)}
            >
              <i className="fa fa-copy"></i>
            </button>
          </center>
        ),
      });
    });

    return products;
  };

  if (isLoading || loadingAdmin) return <Loader />;

  return (
    <>
    <MetaData title={"All Products"} />
    <HeaderAdmin title={`${categoryName} ${categoryOtherName}`} />
      <AdminLayout menuItem={ADMIN_MENU_ITEMS.PRODUCTS.name}>
        <div className="col-11 col-lg-11">
          <div className="row my-4">
            {/* Filter by category */}
            <div className="col-4 my-0">
              <text className="text-start" style={{ display: 'block', width: '100%' }}>Category</text>
              <select className="form-select"
                aria-label="Select category"
                defaultValue="all"
                onChange={handleCategoryChange}
              >
                <option key = "all" value="all">All Products</option>
                {PRODUCT_CATEGORIES.map((category) => (
                  <option key={category.id} value={category.id}>
                    {category.name}
                  </option>
                ))}
              </select>
            </div>

            {/* Filter by status */}
            <div className="col-3 offset-1 my-0">
              <text className="text-start" style={{ display: 'block', width: '100%' }}>Status</text>
              <select className="form-select"
                aria-label="Select status"
                defaultValue="all"
                onChange={handleStatusChange}
              >
                <option key = "all" value="all">All Status</option>
                <option key={"active"} value='true'>Active</option>
                <option key={"inactive"} value='false'>Inactive</option>
              </select>
            </div>

            {/* Filter by displayed on Price List */}
            <div className="col-3 offset-1 my-0">
              <text className="text-start" style={{ display: 'block', width: '100%' }}>Price List</text>
              <select className="form-select"
                aria-label="Select filter on price list"
                defaultValue="all"
                onChange={handlePriceListFilterChange}
              >
                <option key = "all" value="all">All</option>
                <option key={"yes"} value='false'>Yes</option>
                <option key={"no"} value='true'>No</option>
              </select>
            </div>
          </div>


          <div className="row my-4">
            <div className="col-3 col-lg-3 my-0">
            </div>
            <div className="text-center col-4 col-lg-4  offset-1">
              <h3 className="my-2">{data?.products?.length} Products</h3>
            </div>
            <div className="col-3 col-lg-3 offset-1 text-end">
              <Link to="/admin/products/new" className="btn btn-primary">
                Create New Product
              </Link>
            </div>
          </div>
          {/* Loader */}
          {(updatingMultipleProducts || isDeleteLoading) && (
            <div style={{position: 'relative', height: '100%', width: '100%'}}>
              <div style={{position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', zIndex: 6}}>
                <Loader />
              </div>
            </div>
          )}
          <MDBDataTable
            data={setProducts()}
            className="px-3 myMDBDataListProductTable"
            entriesOptions={[10, 20, 50, 100, 200, 500, 1000]}
            entries={1000}
            bordered
            striped
            hover
            noBottomColumns
          />
          <div className="row my-4">
            <div className="col-3 osset-9">
              <button 
                  disabled={updatingMultipleProducts}
                  onClick={() => {updateMultipleProducstHandler();}} 
                  className="btn btn-success"
                  style={{width: '100%'}}
              >
                  Update All
              </button>
            </div>
          </div> 
        </div>
      </AdminLayout>
    </>
  );
};

export default ListProducts;